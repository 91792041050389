/*Hero section start*/
.section-img-bg-67m41f74{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_online-konzultacio.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding-top: 25rem;
      padding-bottom: 5rem;
      overflow: hidden;
      
}

@media (max-width: 450px){
      .section-img-bg-67m41f74{
      padding-top: 20rem;
      padding-bottom: 5rem;
      
}
}

@media (max-width: 310px){
      .section-img-bg-67m41f74{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
      
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-heading-title span{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
}

.hero-title-45m11f77 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-45m11f77 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*online first section starts*/
.mf-online-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 1rem;
        
}

.mf-online-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-online-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-online-title-87m92f34 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-online-text-21m35f73 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
      padding: 1rem;
}
/*online first section ends*/

/*online second section starts*/
.mf-online1-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
       
}

.mf-online1-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-online-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-online-title-24m56f77 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
      padding-bottom: 2rem;
}

.mf-online-text-30m88f62 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
      padding-left: 1rem;
}

.mf-online-text-37m88f60 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-rosa);
      text-align: left;
      line-height: 1.5;
      padding-left: 1rem;
}

.mf-online-content-text{
      position: relative;
}

.mf-online-content-text .mf-online-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-online-list .online-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-online-list .online-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-online-list .online-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}
/*online second section ends*/

/*contact törölve*/

