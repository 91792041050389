/*hero section starts*/
.section-img-bg-86m36f35{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_valasi_tanacsadas.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .section-img-bg-86m36f35{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}


@media (max-width: 450px){
      .section-img-bg-86m36f35{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-86m36f35{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
}


.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/
      
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-heading-title span{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
}

.hero-title-21m12f56 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-21m12f56 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*divorce coaching first section*/

.mf-dc-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 2.5em;
      padding-bottom: 2.5em;
      overflow: hidden;
}

.mf-dc-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: gold;*/
}


.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-dc-row{
      position: relative;
      display: flex;
      width: 100%;
      padding-left: -15px;
      padding-right: -15px;
     
      /*background: navajowhite;*/
}


.mf-dc-row .mf-dc-left{
      flex: 0 0 40%;
      text-align: center;
      position: relative;
}

.mf-dc-img{
      position: relative;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      /*background:greenyellow;*/
}

.mf-dc-img img{
      height: 20em;
      margin: 2rem auto;
}




.mf-dc-right{
      flex: 0 0 60%;
      align-items: center;
      justify-content: center;
      text-align: left;
      position: relative;
}

.mf-dc-title-48m34f29 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-4);
      font-weight: var(--fw-700);
      line-height: 1.5;
      text-align: left;
      margin-top: 1rem;
      padding-bottom: 1rem;
}

.mf-dc-title-73m86f92 .mf-heading-title{
      color: var(--bs-rosa);
      font-family: var(--ff-lato);
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      line-height: 1.5;
      text-align: left;
      margin-top: 1rem;
}

/*divorce coaching second section*/
.mf-dc-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 2.5em;
      padding-bottom: 2.5em;
      overflow: hidden;

}

.mf-dc-container2{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: gold;*/
}


.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-dc-row-1{
      display: flex;
      flex-wrap: wrap;
      position: relative;
      width: 100%;
      padding-left: -15px;
      padding-right: -15px;
      /*background: navajowhite;*/
}


.mf-dc-row-1 .mf-dc-left-1{
      flex: 0 0 50%;
      align-items: center;
      justify-content: center;
      text-align: left;
}

.mf-dc-title-76m39f21 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-4);
      font-weight: var(--fw-700);
      line-height: 1.5;
      text-align: left;
      margin-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
}


.mf-dc-text-27m63f03 .mf-text-description{
      color: var(--bs-light);
      font-family: var(--ff-lato);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      margin-top: 1rem;
      padding-bottom: 1rem;
      padding-left: 1rem;
}

.mf-dc-row-1 .mf-dc-right-1{
      flex: 0 0 50%;
      position: relative;
      text-align: center;
      
}


.mf-dc-img-1{
      height: 100%;
      position: relative;
      /*justify-content: center;
      align-items: center;
      text-align: center;*/
      /*background:greenyellow;*/
}

.mf-dc-img-1 img{
      height: 40em;
      text-align: center;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      overflow: hidden;
}

@media (max-width: 768px){

      .mf-dc-row-1 .mf-dc-left-1{
            flex: 0 0 100%;
      }


      .mf-dc-row-1 .mf-dc-right-1{
            display: none;
      }
      
}

@media (max-width: 650px){
      .mf-dc-row{
            display: grid;
            width: 400px;
      }
}

/*divorce coaching 3 section strart*/
.mf-dc-section3{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3em;
      padding-bottom: 0;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-dc-container3{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;   
}

.widget-container-img{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 0px;
      margin-top: 2rem;
      margin-bottom: 2rem;
      
}

.widget-container-img img{
      height: 10rem;
      width: 10rem;
}


.mf-dc-title-48m54f75 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-lato);
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      line-height: 1.5;
      text-align: center;
      margin-top: 1rem;
      padding-bottom: 0;
      padding-left: 1rem;
}

.mf-dc-title-48m54f75 span{
      color: var(--bs-primary);
      font-family: var(--ff-lato);
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      line-height: 1.5;
      
}
/*divorce coaching 3 section end*/

/*divorce coaching 4 section strart*/
.mf-dc-section4{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 0;
      margin-bottom: 5rem;
}


.mf-dc-container4{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, auto));
      justify-content: center;
      gap: 3rem;
      
}

.mf-dc-img-card{
      box-shadow: 0 14px 28px hsl(355deg 25% 15% / 10%);
      padding: 15px;
      border-radius: 0.5rem;
      border: 0.5px solid var(--bs-card-bg);
      text-align: center;
      position: relative;
      transition: 0.3s;
}

.mf-dc-img-card:before{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 3px solid var(--bs-rosa);
      border-left: 3px solid var(--bs-rosa);
      transition: .3s;
      left: 0;
      top: 0;
      opacity: 0;
}

.mf-dc-img-card:after{
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-bottom: 3px solid var(--bs-rosa);
      border-right: 3px solid var(--bs-rosa);
      transition: .3s;
      right: 0;
      bottom: 0;
      opacity: 0;
}

.mf-dc-img-card:hover:before, .mf-dc-img-card:hover:after{
      width: 50%;
      height: 25%;
      opacity: 1;
}

.mf-dc-img-card img{
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      height: 10em;
      object-fit: cover;
      
}

.mf-dc-title-27m68f92 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-7);
      font-weight: var(--fw-700);
      line-height: 1.5;
      margin-top: 1rem;
}

.mf-dc-text-34m54f86 .mf-text-description{
      color: var(--bs-light);
      font-family: var(--ff-lato);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: center;
     
}

/*divorce coaching 4 section end*/

/*divorce coaching 5 section starts*/

/*divorce coaching 5 section end*/

/*Contact section starts*/

