/*Hero section start*/
.section-img-bg-26m39f10{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_arak.png);
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
  }
  
  @media (max-width: 1028px){
      .section-img-bg-26m39f10{
      padding-top: 25rem;
      padding-bottom: 5rem;
  }
  } 
  @media (max-width: 450px){
      .section-img-bg-26m39f10{
      padding-top: 15rem;
      padding-bottom: 0.1rem;
  }
  }
  
  @media (max-width: 310px){
      .section-img-bg-26m39f10{
            padding-top: 7rem;
            padding-bottom: 2rem;
  }
  }
  
  
  
  .mf-home-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: orange;*/
     
  }
  
  .mf-home-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
      
  }
  
  @media (min-width: 768px){
      .mf-home-column-wrap .mf-col-100{
            width: 100%;
      }
      
  }
  
  
  .mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: #000;*/
      
  }
  
  /*Hero text*/
  
  .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
  }
  
  .hero-title-42m34f89 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
  }
  
  @media (max-width: 450px){
      .hero-title-42m34f89 .mf-heading-title{
      font-size: var(--fs-2);
      }
  }
  /*hero section ends*/

/*rates first section starts*/
.mf-rates-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-rates-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-rates-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem;
      border-radius: 5px; 
      border: 1px solid var(--bs-secondary)
}

.mf-rates-title-15m43f29 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 5rem 0;
}

.mf-rates-content-text{
      position: relative;
      
}

.mf-rates-content-text .mf-rates-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
      
}

.mf-rates-list .rates-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-rates-list .rates-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-rates-list .rates-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}
/*rates first section ends*/






/*rates second section starts*/
.mf-rates1-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-rates1-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;       
}

.mf-rates1-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-rates-title-68m25f16 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-rates-content-text{
      position: relative;
}

.mf-rates-text-78m26f12 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.7px;
      padding: 1rem;
}

/*rates second section ends*/








/*rates -3 section starts*/
.mf-rates2-section3{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-rates2-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;       
}

.mf-rates2-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-rates-title-69m25f35 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-secondary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-rates-content-text{
      position: relative;
}

.mf-rates-text-74m29f10 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.7px;
      padding: 1rem;
}

.mf-rates-text-74m28f11 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      letter-spacing: 0.7px;
      padding: 1rem;
}

.mf-rates-title-57m21f79 .mf-heading-title{
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-rosa);
      text-align: center;
      line-height: 1.5;
      margin: 4rem 0;
}
/*rates -3 section ends*/

/*Contact törölve */







