
.mf-section-img-bg-68m37f92{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_hero_contact_form.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding-top: 15rem;
      padding-bottom: 5rem;
      overflow: hidden;
}
@media (max-width: 1028px){
      .mf-section-img-bg-68m37f92{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .mf-section-img-bg-68m37f92{
      padding-top: 20rem;
      padding-bottom: 1rem;
}
}

@media (max-width: 310px){
      .mf-section-img-bg-68m37f92{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-home-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 10px;
      /*background: #333;*/
      
}

@media (min-width: 768px){
      .mf-home-column-wrap .mf-col-100{
            width: 100%;
      }
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: #000;*/
      
}

/*A hero text kezdete*/

.mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.hero-title-23m42f16 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
      
}

@media (max-width: 450px){
      .home-title-7e1f8217 .mf-home-heading-title{
      font-size: var(--fs-6);
      }

      .home-text-71642346 .mf-home-text-description{
            font-size: var(--fs-7); 
      } 
}
/*hero section ends*/


.mf-contact-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding-top: 3rem;
      padding-bottom: 3rem;
      overflow: hidden;
      background: url(../image/mf_contact_form_2_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
}

.mf-contact-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,0.1);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      /*background: palevioletred;*/
}

.mf-contact-title-21m23f47 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      letter-spacing: 0.7px;
      margin-top: 1rem; 
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}



.mf-contact-section .mf-contact-row .mf-contact-content{
      flex: 0 0 30%;
      align-items: center;
      justify-content: center;
      text-align: center;
      /*background: orange;*/
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-title{
      text-transform: uppercase;
      color: var(--bs-primary);
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      margin: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3{
      display: flex;
      align-items: center;
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      color: var(--bs-primary);
      padding: 1rem 0;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3 i{
      padding-right: 1rem;
      color: var(--bs-rosa);
}

.mf-contact-section .mf-contact-row .mf-contact-form{
      flex: 0 0 60%;
      padding: 1rem;
      margin: 1rem;
      margin-bottom: 4rem;
      margin-left: 2rem;
      text-align: left;
      position: relative;
      /*background: green;*/
}

.mf-contact-section .mf-contact-row .mf-contact-form .box{
      position: relative;
      padding: 1rem 1rem;
      margin: 0.5rem 0;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      text-transform: none;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      width: 80%;
}

.mf-contact-section .mf-contact-row .mf-contact-form .massage{
      height: 10rem;
      resize: none;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 1rem 1.5rem;
      margin: 1rem 0;
}

.mf-contact-form .contact-btn{
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      background: var(--bs-primary);
      color: var(--bs-white);
      cursor: pointer;
      font-size: var(--fs-7);
      letter-spacing: 1px;
      border-radius: 2rem;
      box-shadow: var(--box-shadow);
      transition: .5s ease;
}
.mf-contact-form .contact-btn i{
      padding: 0 .5rem;
      font-size: var(--fs-8);   
}

.mf-contact-form .contact-btn:hover{
      color: var(--bs-secondary);
}

/*contact first section starts */

.mf-contact-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 3rem;
      margin-bottom: 3rem;
}

.mf-contact-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-contact-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      text-align: left;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.1);
}
.mf-contact-title-86m12f24 .mf-heading-title{
      font-size: var(--fs-2);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}
/*contact first section ends */

/*Contact second section start*/
.mf-contact1-section2{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-secondary);
      overflow: hidden;
      margin-top: 2rem;
}

.mf-contact1-container{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, auto));
      justify-content: center;
      gap: 3rem;
}
/*Info content*/
.mf-contact-info-box{
      background-color: rgba(255,255,255,0.1);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      padding: 15px;
      align-items: center;
      justify-content: center;
      

}

.contact-title{
      color: var(--bs-primary);
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      margin: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.contact-description{
      color: var(--bs-primary);
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      margin: 2rem 0;
      padding-top: 2rem;
      line-height: 1.5;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.contact-text{
      color: var(--bs-primary);
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      margin: 2rem 0;
      padding: 2rem 0;
      line-height: 1.5;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.contact-info{
      border-radius: 20px;
      box-shadow: var(--box-shadow);
}


.contact-info h3{
      display: flex;
      align-items: center;
      font-size: var(--fs-7);
      font-weight: var(--fw-400);
      color: var(--bs-primary);
      padding: 1rem 3rem;
      
}

.contact-info h3 i{
      padding-right: 2rem;
      color: var(--bs-light);
}

/*Form box-starts*/
.mf-contact-form-box{
      /*background-color: rgba(255,255,255,0.1);*/
      background-color: var(--bs-primary);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      padding: 15px;
      align-items: center;
      justify-content: center;
}

.mf-contact-form-box .mf-cont-title-21m74f59 .mf-heading-title{
      color: var(--bs-white);
      font-size: var(--fs-5);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      margin-bottom: 2rem;
      letter-spacing: 0.7px;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.mf-contact-form-box .mf-home-contact-input-box{
      position: relative;
      width: 100%;
      margin-bottom: 20px;
}

.mf-contact-form-box .mf-home-contact-input-box input,
.mf-contact-form-box .mf-home-contact-input-box textarea{
      color: var(--bs-light);
      width: 100%;
      padding: 1rem 1.5rem;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      outline: none;
      border: .1rem solid var(--bs-secondary);
      border-radius: 1rem;
      resize: none;
}

.mf-home-contact-check-box{
      color: var(--bs-white);
      font-size: var(--fs-9);
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;

}

.mf-home-contact-check-box p{
      color: var(--bs-white);
      font-size: var(--fs-9);
      padding-left: 1rem;
      padding-top: 1rem;
      
}

.mf-home-contact-check-box a{
      color: var(--bs-black);
      font-size: var(--fs-8);
      padding-left: 1rem;
      padding-top: 1rem;
      text-decoration: underline;
      
}

.mf-landing-contact-check-box{
      color: var(--bs-white);
      font-size: var(--fs-9);
      display: flex;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      margin-bottom: 1rem;
}

.mf-landing-contact-check-box p{
      color: var(--bs-primary);
      font-size: var(--fs-11);
      padding-left: 1rem;
      padding-top: 1rem;  
}

.mf-landing-contact-check-box a{
      color: var(--bs-black);
      font-size: var(--fs-11);
      padding-left: 1rem;
      padding-top: 1rem;
      text-decoration: underline;
}

.mf-contact-form-box .mf-home-contact-input-box .send-btn{
      color: var(--bs-white);
      background: var(--bs-secondary);
      display: inline-block;
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      text-transform: uppercase;
      letter-spacing: 2px;
      width: 30%;
      border-radius: 25px;
      border: none;
      cursor: pointer;
      transition: 0.5s ease;
      margin-top: 1rem;
}

.mf-contact-form-box .mf-home-contact-input-box .send-btn:hover{
      background: var(--bs-rosa);
}

/*Contact section starts all page*/

.mf-contact-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding-top: 3rem;
      padding-bottom: 3rem;
      overflow: hidden;
      background: url(../image/mf_contact_form_1_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
}

.mf-contact-container{
      display: flex;
      flex-direction: column;
      position: relative;
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      /*background: palevioletred;*/
      /*background-color: rgba(255,255,255,0.1);*/
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);

}

.mf-adult-title-21m23f47 .mf-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      letter-spacing: 0.7px;
      margin-top: 1rem; 
}

.padd-10{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-contact-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: -15px;
      padding-right: -15px;
      justify-content: center;
      /*background: navajowhite;*/
      

}

.mf-contact-section .mf-contact-row .mf-contact-content{
      flex: 0 0 30%;
      align-items: center;
      justify-content: center;
      text-align: center;
      /*background: orange;*/
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-title{
      text-transform: uppercase;
      color: var(--bs-primary);
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      margin: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3{
      display: flex;
      align-items: center;
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      color: var(--bs-primary);
      padding: 1rem 0;
      padding-right: 1rem;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3 i{
      padding-right: 1rem;
      padding-left: 1rem;
      color: var(--bs-rosa);
}


.mf-contact-section .mf-contact-row .mf-contact-form{
      flex: 0 0 60%;
      padding: 1rem;
      margin: 1rem;
      margin-bottom: 4rem;
      margin-left: 2rem;
      text-align: left;
      position: relative;
      /*background: green;*/
}

.mf-contact-section .mf-contact-row .mf-contact-form .box{
      position: relative;
      padding: 1rem 1rem;
      margin: 0.5rem 0;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      text-transform: none;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      width: 80%;
}

.mf-contact-section .mf-contact-row .mf-contact-form .massage{
      height: 12rem;
      resize: none;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 1rem 1.5rem;
      margin: 1rem 0;

}

.mf-contact-form .contact-btn{
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      background: var(--bs-primary);
      color: var(--bs-white);
      cursor: pointer;
      font-size: var(--fs-7);
      letter-spacing: 1px;
      border-radius: 2rem;
      box-shadow: var(--box-shadow);
      transition: .5s ease;
}
.mf-contact-form .contact-btn i{
      padding: 0 .5rem;
      font-size: var(--fs-8);   
}

.mf-contact-form .contact-btn:hover{
      color: var(--bs-secondary);
}

@media (max-width: 768px){
      .mf-contact-section .mf-contact-row .mf-contact-form .box{
            width: 250px;
      }  
}

/*Contact section ends*/


