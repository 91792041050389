
/* Hero starts*/

.section-img-bg-3m3f1622{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf-szolgaltatasok-bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 15rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .section-img-bg-3m3f1622{
      padding-top: 25rem;
      padding-bottom: 0.1rem;
}
}
@media (max-width: 450px){
      .section-img-bg-3m3f1622{
      padding-top: 20rem;
      padding-bottom: 0.1rem;
}
}

@media (max-width: 310px){
      .section-img-bg-3m3f1622{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}



.mf-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: orange;*/  
}


.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 10px;
      /*background: #333;*/
      
}

@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }     
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: #000;*/
      
}

/*A herohoz a szöveg kezdete*/
.mf-widget-heading-title .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
}

.title-85f6e329 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

.mf-heading-title{
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-widget-text-description .mf-text-description{
      color: var(--bs-light1);
      font-size: var(--fs-9);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      
}

.text-51b7e42c .mf-text-description{
      color: var(--bs-white);
      font-size: var(--fs-5);
      font-family: var(--ff-lato);
      font-weight: var(--fw-500);
      padding-top: 5rem;
}

@media (max-width: 450px){
      .title-85f6e329 .mf-heading-title{
      font-size: var(--fs-2);
      }

      .text-51b7e42c .mf-text-description{
            font-size: var(--fs-7); 
      } 
}
/*hero service section ends */

/*sercice-content starts*/

.mf-section-service-content{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 2rem 2rem;
      background-color: var(--bs-body-bg);
      background-size: cover;
      /*background: #136a80;*/
}

.mf-home-service-container{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 2rem;
      margin-right: 30px;
      margin-left: 30px;
      /*background: #f84b70;*/
}

@media (max-width: 1200px){
      .mf-home-service-container{
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            margin-right: 30px;    
      }

      .mf-col-md-33{
            max-width: 100%;
      }
}

@media (max-width: 900px){
      .mf-home-service-container{
            grid-template-columns: 1fr;
            margin-left: 1rem !important;
            margin-right: 1rem !important;
            
      }
      .mf-col-15{
            max-width: 100%;
      }
}

.pt-5{
      padding-top: 3rem !important;
}

.px-0{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
}

@media (min-width: 992px){
      .py-lg-5{ 
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
      }
      .px-0{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
      }
}

@media (min-width: 540px){
      .py-lg-5{ 
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
      }
      .px-0{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
      }
}

.mf-col-15{
      position: relative;
      margin: 2rem;
      padding: 2rem;
      width: 85%;
      height: 400px;
      text-align: center;
      background: linear-gradient(-20deg, #dfe9eb 65%, #136a80 10%);
      box-shadow: var(--box-shadow);
      border-top-right-radius: 10%;
      border-bottom-left-radius: 10%;
      cursor: pointer;
      overflow: hidden;
}

@media (max-width: 425px){
      .mf-home-service-bkg{
            margin: -15px;
      }
}

.p-2{
      padding: 0.5rem !important;
}

#profile1{
      width: 160px;
      border-radius: 50%;
      margin: 60px auto;
      position: relative;
      z-index: 2;
      transition: .4s;
}

.mf-col-15:hover{
      background: var(--bs-light1);
      
}

.mf-col-15:hover #profile1{
      transform: translate(-110px, -30px);
      width: 100px;
}

@media (max-width: 520px){
      .mf-col-15:hover #profile1{
            transform: translate(-60px, -30px);
      } 
}

.mf-widget-home-service-heading-title{
      margin: 0 20px;
}

.mf-col-15:hover .mf-widget-home-service-heading-title{
      margin: -30px;
}

.home-service-title-29fe5372 .mf-home-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-5);
      font-weight: var(--fw-600); 
      transform: translateY(-40px); 
}

.mf-col-15:hover .home-service-title-29fe5372 .mf-home-heading-title{
      transform: translate(0px, -60px);
      color: var(--bs-rosa);
      font-size: var(--fs-7);
      
}


.mf-home-service-bkg{
      height: 120px;
      width: 100%;
      background: var(--bs-primary);
      position: absolute;
      z-index: 2;
      top: 0px;
      margin: -5px;
      transition: .5s;     
}


.mf-col-15:hover .mf-home-service-bkg{
      height: 90px;
      top: 335px;
      background: var(--bs-secondary);
}

.mf-widget-home-service-text-description{
      margin-top: -50px;
      width: 100%;
      padding: 1rem;
      opacity: 0;
}

.home-service-text-31f6b357 .mf-home-sevice-text-description{
      color: var(--bs-light);
      font-family: var(--ff-lato);
      font-size: var(--fs-8);
      font-weight: var(--fw-400); 
      margin: 0px auto;
      padding: 2rem;
      transition: .5s;
}

.mf-col-15:hover .mf-widget-home-service-text-description{
      opacity: 1;
}

.mf-col-15:hover .home-service-text-31f6b357 .mf-home-service-text-description{
      transform: translateY(220);
      font-size: var(--fs-8);
      line-height: 1.5;
      border-top: 2px solid #81b2bb;
}

.mf-home-service-soc{
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 0px;
      width: 100%;
}

.mf-home-service-btn{
      margin: 2rem;
      padding: 2rem;
      font-size: var(--fs-9);
      font-weight: var(--fw-500);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      box-shadow: var(--box-shadow);
      align-items: center;
      color: var(--bs-rosa);
      opacity: 1;
}

.mf-col-15:hover .mf-home-service-btn{
      opacity: 1;
      z-index: 2;
      margin: 1rem;
      padding: 2rem;
}
/*home-service-content ends*/

/*innen töröltem a contact css-t*/

