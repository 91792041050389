/*blog-first-section starts*/

.section-blog-globale{
      display: flex;
      position: relative;
      justify-content: center;
      margin-top: 110px;
}
.mf-blog-globale-container{
      padding-inline: 26px;
      width: 100%;
      margin-inline: auto;
  }
  
  .mf-blog-globale-container .mf-blog-globale-hero{
      max-width: 100%;
      overflow: hidden;
      background-position: center;
  }
  
  .mf-blog-globale-hero img{
      width: 100%;
      height: 50em;
      object-fit: cover;
  }


@media (max-width: 450px){
      .section-blog-globale{
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
    .mf-blog-globale-hero img{
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

}

@media (max-width: 320px){
      .section-blog-globale{
            padding-top: 7rem;
            padding-bottom: 2rem;
}


}
  .mf-blog-globale-face{
      width: 100%;
      max-width: 120rem;
      /*background-color: #f8f4f1;*/
      margin: 0 auto;
      padding: 3rem;
  }
  
  .mf-blog-globale-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
  }

  .mf-blog-globale-title-2f3b5530 .mf-blog-globale-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-700);
      color: var(--bs-primary);
      /*text-transform: uppercase;*/
      letter-spacing: 2px;
  }
  

  @media (max-width: 768px){
      .mf-blog-globale-title-2f3b5530 .mf-blog-globale-heading-title{
            font-size: var(--fs-6);
      }
  }

  @media (max-width: 450px){
      .mf-blog-globale-title-2f3b5530 .mf-blog-globale-heading-title{
            font-size: var(--fs-6);
      }
  }


  .mf-blog-globale-face .mf-blog-globale-profile-container{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 3rem 0;
  }
  
  .mf-blog-globale-face .mf-blog-globale-profile-container .mf-blog-globale-profile{
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .mf-blog-globale-face .mf-blog-globale-profile-container .mf-blog-globale-profile .mf-blog-globale-img-container{
      width: 6rem;
      height: 6rem;
      background: url(../image/gemeter_zsuzsa.png)no-repeat
      center center /cover;
      border-radius: 50%;  
  }
  
  .mf-blog-globale-text-description{
      color: var(--bs-light);
      font-family: var(--ff-lato);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
  } 
  
  
  .mf-blog-globale-text-2f5f3421 .mf-blog-globale-text-description{
      margin: 0 1rem;
      color: var(--bs-light);
      font-size: var(--fs-10);
      font-weight: 400;
      letter-spacing: 1px;
      line-height: 2em;
  }
  
  
  .mf-blog-globale-btn{
      display: inline-block;
      text-transform: none;
      font-size: var(--fs-6);
      padding: 0.3rem 1.5rem;
      background-color: var(--bs-primary);
      border-radius: 0.3rem;
      color: var(--bs-white);
  }
  
  .mf-blog-globale-btn:hover{
      background: var(--bs-secondary);
      color: var(--bs-primary);   
  }
  
.mf-blog-globale-column{
      padding: 2em;
}

.mf-blog-globale-img-container{
      max-width: 100%;
      align-self: center;  
      overflow: hidden;
}

.mf-blog-globale-img-container img{
      width: 100%;
      height: 50rem;
      margin-top: 3rem;
      object-fit: cover;
      background-position: center;
}

@media (max-width: 450px){
    .mf-blog-globale-img-container img{
        width: 100%;
        height: 200px;
  }
}


.mf-blog-globale-text{
      padding: 2em;
}

.mf-blog-globale-container p{
      line-height: 1.5;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      color: var(--bs-light);
      margin: 2rem 0;
  }

.mf-blog-globale-container p i{
      line-height: 1.5;
      font-size: var(--fs-7);
      font-weight: var(--fw-400);
      color: var(--bs-light);
      margin: 2rem 0.5rem;
}

.mf-blog-globale-container p b{
      line-height: 1.5;
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      
}

.mf-blog-globale-container p a{
      line-height: 1.5;
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      text-decoration: underline;
      
}
.mf-blog-globale-container h4{
      font-size: var(--fs-6);
      font-weight: var(--fw-800);
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      margin-top: 4rem;
      margin-bottom: 3rem;
  }

.mf-blog-globale-container h6{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-600);
      color: var(--bs-light);
      line-height: 2em;
      margin: 1.5rem 0;
}


  .mf-blog-globale-container .blog-globale-list{
      margin: 3rem 1rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
  }
  
  .blog-globale-list .blog-globale-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 10px;
      line-height: 1.5;
  }

  .blog-globale-list .blog-globale-has-before:not(:last-child) {margin-block-end: 15px;}

  .blog-globale-list .blog-globale-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:-10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
  }

  
/*blog- globale-list1 starts*/

  .blog-globale-list1 .blog-globale-has-before1:not(:last-child) {margin-block-end: 15px;}

  .blog-globale-list1 .blog-globale-has-before1::before{
      content: '';
      position: absolute;
      top: 10px;
      left:-10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-secondary);
  }


.mf-blog-globale-container .blog-globale-list1{
      margin: 4rem 3rem;
      display: grid;
      grid-template-columns: 1fr;
}

@media (max-width: 450px){
    .mf-blog-globale-container .blog-globale-list1{
        margin: .4rem .3rem;
    }
}


.mf-blog-globale-container .blog-globale-list1{
      padding: 1rem;
}

.blog-globale-list1 .blog-globale-has-before1{

      position: relative;
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      padding-inline-start: 10px;
      line-height: 1.5;
      font-style: italic;
  }

/*blog- globale-list1 ends*/


/*tags start*/

.mf-blog-global-tags{
      display: flex;
      align-items: center;
  }
  
.mf-blog-global-tags p{
      font-size: var(--fs-9);
      text-transform: capitalize;
  }
  
.mf-blog-global-tags a{
      display: inline-block;
      padding: 0.3rem 1.5rem;
      background: var(--bs-rosa);
      color: var(--bs-white);
      font-size: var(--fs-9);
      text-transform: capitalize;
      margin: 0 0.5rem;
  }
/*tags ends*/

