/*onismeret trening hero section start*/
.section-img-bg-73m11f89{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_onismereti_trening.png);
      background-repeat: no-repeat;
      background-position:center center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}
@media (max-width: 1200px){
      .section-img-bg-73m11f89{
            padding-top: 30rem;
            padding-bottom: 5rem;
      }

}
@media (max-width: 450px){
      .section-img-bg-73m11f89{
            padding-top: 20rem;
            padding-bottom: 5rem;
      }

}

@media (max-width: 310px){
      .section-img-bg-73m11f89{
            padding-top: 7rem;
            padding-bottom: 2rem;
      }
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/   
}

/*A hero text*/

 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.hero-title-69m71f93 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-69m71f93 .mf-heading-title{
      font-size: var(--fs-2);
      }
}



/*önismeret trening hero section ends*/


/*önismeret trening first section starts*/

.mf-self-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 2rem;
      margin-bottom: 2rem;
}

.mf-self-container{
      display: flex;
      position: relative;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
}


.mf-self-title-38m42f56 .mf-heading-title{
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
     
}


.mf-self-text-20m53f48 .mf-text-description{
      color: var(--bs-rosa);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
      line-height: 1.5;
}

@media (max-width: 425px){
      .mf-self-title-38m42f56 .mf-heading-title{
            font-size: var(--fs-3);
      }
}

/*self trening first section ends*/

/*self trening second section starts*/
.mf-self-section-1{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      background-size: cover;
      overflow: hidden;
}

.mf-self-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}
.mf-self-row{
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.self-img-box{
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
      position: relative;
      object-fit: cover;
}

.self-img-box img{
      height: 30em;
      margin: auto;
      border-radius: 5px;
      box-shadow: inset 0 5px 1px rgba(0,0,0,0.1),
      0 5px 15px rgba(0,0,0,0.1),
      0 -5px 15px rgba(0,0,0,0.15);
}


.self-box-1{
      flex: 0 0 60%;
      max-width: 60%;
}

.mf-self-text-21m01f97 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}



/*self 2 section ends*/

/*self trening 3 section starts*/
.mf-self-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 2rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-self-container1{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center; 
}

.mf-self-text-box{
      width: 100%;
      padding: 1rem;
      margin: 2rem;
      /*text-align: left;*/
      overflow: hidden; 
      
}

.mf-self-title-40m42f50 .mf-heading-title{
      font-size: var(--fs-4);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-self-content-text{
      position: relative;
}

.mf-self-content-text .mf-self-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-self-list .self-has-before{
      position: relative;
      font-size: var(--fs-7);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-self-list .self-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-self-list .self-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

@media (max-width: 650px){
      .mf-self-content-text .mf-self-list{
            margin: 0;
            display: grid;
            grid-template-columns: 1fr;
            list-style: none;
      }
}
/*Self trening 3 section ends*/

/*Self-trening 4 section starts*/
.mf-self-info-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 2rem;
      padding-bottom: 2rem;
      overflow: hidden;
}

.mf-self-info-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
}

.mf-self-info-btnBx{
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
}

.mf-self-btn{
      margin: 2rem;
      padding: 3rem;
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 0.5rem;
      border-radius: 2rem;
      border: .2rem solid var(--bs-rosa);
      background: var(--bs-rosa);
      box-shadow: var(--box-shadow);
      align-items: center;   
      color: var(--bs-white);
}

.mf-self-btn:hover{
      background: var(--bs-primary);
      color: var(--bs-white);
      border: none;
}

@media (max-width: 650px){
      .mf-self-btn{
            margin: 1rem;
            padding: 2rem;
            letter-spacing: 0.7px;
            line-height: 3.5rem;
      }  
}
/*self trening 4 section ends*/

/*self-trening 5section starts*/
.mf-self-section-5{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      background-size: cover;
      overflow: hidden;
}

.mf-self-container5{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}
.mf-self-row{
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.self-img-box-2{
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
      position: relative;
      object-fit: cover;
}

.self-img-box-2 img{
      height: 40em;
      margin: 0rem;
      /*border-radius: 5px;
      box-shadow: inset 0 5px 1px rgba(0,0,0,0.1),
      0 5px 15px rgba(0,0,0,0.1),
      0 -5px 15px rgba(0,0,0,0.15);*/
}

.self-box-3{
      flex: 0 0 60%;
      max-width: 60%;
}

.mf-self-title-48m21f39 .mf-heading-title{
      font-size: var(--fs-4);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 2rem;
}
@media (max-width: 756px){
      .self-img-box-2 img{
            display:none;
      }
      .mf-self-row{
            display: grid;
            width: 110%;
      }
      .mf-self-section-1 .self-img-box{
            max-width: 100%;
      }
      .self-box-1{
            flex: 0 0 100%;
            max-width: 100%;
      }
      .self-box-3{
            flex: 0 0 100%;
            max-width: 100%;
      }
}



/*self trening 5 section end*/

/*self trening 6 section start*/
.mf-self-section-6{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 1rem;
      margin-bottom: 3rem;
}

.mf-self-container-6{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, auto));
      justify-content: center;
      gap: 3rem;
}

.self-card{
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
      padding: 15px;
      border-radius: 0.5rem;
      border: 0.5px solid var(--bs-card-bg);
}


.self-card-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      background-color: var(--bs-rosa);
      border-radius: 2rem;
      text-align: center;
      margin: 2rem auto;
      padding: 2rem;
      overflow: hidden;

}

.self-card-title-1{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      color: var(--bs-primary);
      font-family: var(--ff-lato);
      text-align: center;
}

.self-card-title-2{
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      color: var(--bs-light);
      font-family: var(--ff-lato);
      text-align: center;
      text-decoration: underline;
      margin-top: 2rem;
}

.self-card-title-3{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      color: var(--bs-primary);
      font-family: var(--ff-lato);
      text-align: center;
}

.self-card-title-3 span{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      color: var(--bs-light);
      font-family: var(--ff-lato);
      line-height: 2;
      text-align: center;
}
/*self trening 6 section end*/

/*self trening 7 section starts*/
.mf-self-section-7{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 1rem;
      margin-bottom: 3rem;
}

.mf-self-container-7{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, auto));
      justify-content: center;
      gap: 3rem;
}

.self-card1{
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
      padding: 15px;
      border-radius: 0.5rem;
      border: 0.5px solid var(--bs-card-bg);
}

.self-card1-title-1{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      color: var(--bs-primary);
      font-family: var(--ff-lato);
      text-align: center;
      margin: 3rem;
}

.self-card1-title-1 span{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      padding-top: 2rem;

}

.self-card1-title-2{
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      color: var(--bs-secondary);
      font-family: var(--ff-lato);
      text-align: center;
      margin-bottom: 2rem;
}

.self-card1-title-3{
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      color: var(--bs-rosa);
      font-family: var(--ff-lato);
      text-align: center;
      padding-top: 3rem;
      padding-bottom: 2rem;
}

/*self trening 7 section ends*/

/*self GYIK toggle start*/
.mf-self-section-8{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-self-container-8{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-self1-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-self1-row .mf-self-info2{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-self-title-24m32f10 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-rosa);
      text-align: left;
      line-height: 1.5;
      margin: 2rem 0;
}
@media (max-width: 650px){
      
      .mf-self1-row .mf-self-info2{
            max-width:100%;
      }
      .mf-self1-row{
            display:contents;
            flex-wrap:nowrap;
            width: 400px;
            margin-left: 5px;
            margin-right: 5px;
            
      }
}

/*togle start*/
.accordion{
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
}

.contentBx{
      position: relative;
      border-bottom: 1px solid var(--bs-secondary);
}


.accordion .contentBx .label8{
      position: relative;
      padding: 20px;
      background: transparent;
      color: var(--bs-primary);
      cursor: pointer;
      font-size: var(--fs-6);
      font-family: var(--ff-baloo);
      font-weight: var(--fw-600);
} 

.accordion .contentBx .label8:before{
      content: '+';
      position: relative;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: var(--bs-secondary);
}

.accordion .contentBx.active .label8{
      background: var(--bs-primary);
      color: var(--bs-white);
}

.accordion .contentBx.active .label8:before{
      content: '-';
      color: var(--bs-rosa);
}

.accordion .contentBx .content8{
      position: relative;
      background: var(--bs-light1);
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      overflow-y: auto;
}

.accordion .contentBx .content8 p{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      padding: 10px 20px;
      margin: 1rem auto;
}


.accordion .contentBx .content8 p a{
      color: var(--bs-primary);
      text-decoration: underline;
}
.accordion .contentBx.active .content8{
      height: auto;
}


.content8 .accordion-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.accordion-list .accordion-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.accordion-list .accordion-has-before:not(:last-child) {margin-block-end: 15px;}

.accordion-list .accordion-has-before::before{
    content: '';
    position: absolute;
    top: 10px;
    left:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--bs-primary);
}
/*togle end*/
/*self trening 8 section ends*/

/*contact section starts*/
.info-text-box{
      padding-bottom: 2rem;
}

.self-info-title-29m50f30 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.8;
      text-align: center;
}
.self-info-text-27m77f98 .mf-text-description{
      font-size: var(--fs-7);
      font-weight: var(--fw-700);
      font-family: var(--ff-lato);
      color: var(--bs-rosa);
      line-height: 1.8;
      text-align: center;
}

.self-info-text-38m10f63 .mf-text-description{
      font-size: var(--fs-7);
      font-weight: var(--fw-700);
      font-family: var(--ff-lato);
      color: var(--bs-rosa);
      line-height: 1.8;
      text-align: center;
}





/*back To Top button start
.back-to-top{
      position: fixed;
      bottom: 20px;
      right: 20px;
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bs-body-bg);
      border: 2px solid var(--bs-rosa);
      border-radius: 50%;
      text-decoration: none;
      transition: 0.2s ease-out;
      cursor: pointer;
}

.back-to-top span{
      color: var(--bs-rosa);
      font-size: 20px;
      justify-content: center;
      transition: 0.2s ease-out;
}

.back-to-top:hover{
      background-color: var(--bs-rosa);
}

.back-to-top span:hover{
      color: var(--bs-white);
      transform: translateY(-4px);
}


@media (max-width:992px) {
      .back-to-top{
            width: 40px;
            height: 40px;
      } 
      .back-to-top span{
            font-size: 15px;
      }
}

@media (max-width:768px) {
      .back-to-top{
            width: 32px;
            height: 32px;
      } 
      .back-to-top span{
            font-size: 12px;
      }
}
back To Top button ends*/


