


/*trening hero section start*/
.section-img-bg-72m13f79{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_trening.png);
      background-repeat: no-repeat;
      background-position:top center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .section-img-bg-72m13f79{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-72m13f79{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-72m13f79{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/   
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}


.hero-title-42m71f95 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-42m71f95 .mf-heading-title{
      font-size: var(--fs-6);
      }
}
/*trening hero section ends*/

/*trening first section starts*/
.mf-trening-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 10rem;
      padding-bottom: 10rem;
      overflow: hidden;  
}

.trening-card{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, auto));
      justify-content: center;
      gap: 3rem;
      
}
   
.trening-card-box{
      background:var(--bs-white);
      box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
      padding: 15px;
      border-radius: 0.5rem;
      border: 0.5px solid var(--bs-card-bg);
}
   
.trening-img{
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 0.5rem;
}
   

   
.trening-box-title{
      font-size: var(--fs-4);
      font-weight: var(--fw-800);
      color: var(--bs-primary);
      /*to remain title in 2 lines*/
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-top: 2rem;
      text-align: center;
      overflow: hidden;
}
  
   
.trening-box-description{
      font-size: var(--fs-7);
      line-height: 1.5;
      margin: 5px 0 10px;
      /*to remain title in 2 lines*/
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      text-align: center;
      overflow: hidden;
}

.trening-btn-box{
      text-align: center;
}

.trening-btn{
      margin: 2rem;
      padding: 2rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 2rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      color: var(--bs-rosa);
      box-shadow: var(--box-shadow);
      align-items: center;
}
/*trening first section ends*/





















/*contact section starts*/
.mf-contact-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding-top: 3rem;
      padding-bottom: 3rem;
      overflow: hidden;
      background: url(../image/mf_contact_form_2_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
}

.mf-contact-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,0.1);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      /*background: palevioletred;*/
}

.mf-contact-title-21m23f47 .mf-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      letter-spacing: 0.7px;
      margin-top: 1rem; 
}

.padd-10{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-contact-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: -15px;
      padding-right: -15px;
      justify-content: center;
      /*background: navajowhite;*/

}

.mf-contact-section .mf-contact-row .mf-contact-content{
      flex: 0 0 30%;
      align-items: center;
      justify-content: center;
      text-align: center;
      /*background: orange;*/
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-title{
      text-transform: uppercase;
      color: var(--bs-primary);
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      margin: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3{
      display: flex;
      align-items: center;
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      color: var(--bs-primary);
      padding: 1rem 0;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3 i{
      padding-right: 1rem;
      color: var(--bs-rosa);
}

.mf-contact-section .mf-contact-row .mf-contact-form{
      flex: 0 0 60%;
      padding: 1rem;
      margin: 1rem;
      margin-bottom: 4rem;
      margin-left: 2rem;
      text-align: left;
      position: relative;
      /*background: green;*/
}

.mf-contact-section .mf-contact-row .mf-contact-form .box{
      position: relative;
      padding: 1rem 1rem;
      margin: 0.5rem 0;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      text-transform: none;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      width: 80%;
}

.mf-contact-section .mf-contact-row .mf-contact-form .massage{
      height: 10rem;
      resize: none;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 1rem 1.5rem;
      margin: 1rem 0;

}

.mf-contact-form .contact-btn{
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      background: var(--bs-primary);
      color: var(--bs-white);
      cursor: pointer;
      font-size: var(--fs-7);
      letter-spacing: 1px;
      border-radius: 2rem;
      box-shadow: var(--box-shadow);
      transition: .5s ease;
}
.mf-contact-form .contact-btn i{
      padding: 0 .5rem;
      font-size: var(--fs-8);   
}

.mf-contact-form .contact-btn:hover{
      color: var(--bs-secondary);
}

/*contact section ends*/









/*back To Top button start*/
.back-to-top{
      position: fixed;
      bottom: 20px;
      right: 20px;
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bs-body-bg);
      border: 2px solid var(--bs-rosa);
      border-radius: 50%;
      text-decoration: none;
      transition: 0.2s ease-out;
      cursor: pointer;
}

.back-to-top span{
      color: var(--bs-rosa);
      font-size: 20px;
      justify-content: center;
      transition: 0.2s ease-out;
}

.back-to-top:hover{
      background-color: var(--bs-rosa);
}

.back-to-top span:hover{
      color: var(--bs-white);
      transform: translateY(-4px);
}


@media (max-width:992px) {
      .back-to-top{
            width: 40px;
            height: 40px;
      } 
      .back-to-top span{
            font-size: 15px;
      }
}

@media (max-width:768px) {
      .back-to-top{
            width: 32px;
            height: 32px;
      } 
      .back-to-top span{
            font-size: 12px;
      }
}
/*back To Top button ends*/







/*Footer starts*/

.copyright{
      background: var(--bs-body-bg);
      text-align: center;
      justify-content: center;
      padding: 2rem;
      background-size: cover;
      background-position: center;
 }

 .copyright .credit{
      text-align: center;
      font-size: var(--fs-9);
      color: var(--bs-primary);

 }

/*Footer ends*/