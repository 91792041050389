/* Hero starts*/
.section-img-bg-07m42f38{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_mediacio.png);
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 450px){
      .section-img-bg-07m42f38{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-07m42f38{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 2rem;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/  
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mediation-title-20m27f47 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .mediation-title-20m27f47 .mf-heading-title{
      font-size: var(--fs-2);
      }
}

/*mediatio-quote-container*/
.mf-container-1{
      display: flex;
      position: relative;
      margin: 2rem auto;
      justify-content: center;
      align-items: center;
      /*background: gray;*/    
}

.mf-mediation-quote-container{
      width: 100%;
      height: 100%;
      justify-content: end;
      /*background: #fff;*/
}

.mediation-quote-box{
      position: relative;
      padding: 1rem auto;
      /*background: #f84b70;*/    
}

.mf-mediation-quote-container h6{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-6);
      font-weight: var(--fw-500);
      line-height: 1.5;
      text-align: end;
      z-index: 2;    
}

.mf-mediation-quote-container p{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-9);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: end;
      z-index: 2;
}

@media (max-width: 450px){
      .mf-container-1{
            margin: 1rem auto;
      }
      .mediation-quote-box{
            padding: 0;
      }
      .mf-mediation-quote-container h6{
            font-size: var(--fs-9);
      }
      .mf-mediation-quote-container p{
            font-size: var(--fs-9);
      }
}

@media (max-width: 310px){
      .mf-mediation-quote-container h6{
            display: none;
      }
      .mf-mediation-quote-container p{
            display: none;
      }
}
/*mediation quote container end*/
/* Hero ends*/

/*Mediation first section starts*/
.mf-mediation-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-mediation-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-mediation-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

@media (max-width: 450px){
      .mf-mediation-row{
            display:grid;
            justify-content: center;
      } 
      .mf-mediation-section .mf-mediation-img{
            max-width: 40%;
      } 
}

.mf-mediation-section .mf-mediation-info{
      flex: 0 0 60%;
      /*max-width: 60%;*/
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-mediation-title-20m43f55 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;

}

.mf-mediation-text-29m67f82 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      padding-bottom: 1rem;
}

.mf-mediation-text-36m58f41 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-700);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
}  

/*img container*/
.mf-mediation-section .mf-mediation-img{
      flex: 0 0 40%;
      max-width: 40%;
      position: relative;
      text-align: center;
      
      /*background: chocolate;*/
}

.mf-mediation-section .mf-mediation-img img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 30em;
}

@media (max-width: 650px){
      .mf-mediation-row{
            display:grid;
            justify-content: center;
      } 
      .mf-mediation-section .mf-mediation-img{
            max-width: 100%;
      } 
}

/*Mediation first section ends*/

/*Mediation second section starts*/
.mf-mediation1-section2{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-secondary);
      margin-top: 3rem;
      padding-bottom: 3rem;
}

.mf-mediation1-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-mediation1-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;  
}

.mf-mediation-title-16m03f27 .mf-heading-title{
      font-size: var(--fs-4);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}

/*Mediation second section ends*/

/*mediation 3 section ends */

.mf-mediatio2-section3{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 0rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-secondary);
}

.mf-mediatio2-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;   
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-mediatio1-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-mediatio2-section3 .mf-mediatio-info1{
      flex: 0 0 50%;
      max-width: 50%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-mediatio2-section3 .mf-mediatio-info2{
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      text-align: left;
      /*background: chocolate;*/
}

.icon-box{
      display: flex;
      align-items: center;
      gap: 2rem;
      margin: 2rem 2rem;
}

.icon-box img{
      width: 5rem;
      height: 5rem;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      border: 2px solid var(--bs-secondary);
}

.icon-box .icon-title{
      font-size: var(--fs-6);
      font-weight: var(--fw-700);
      color: var(--bs-primary);
      text-transform: uppercase;
}

.mf-mediatio2-section3 .mf-mediatio-info1 .mf-mediatio-description,
.mf-mediatio2-section3 .mf-mediatio-info2 .mf-mediatio-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      padding-bottom: 2rem;
      padding-right: 2rem; 
      padding-left: 2rem; 
}

@media (max-width: 650px){
      .mf-mediatio1-row{
            display: grid;
            width: 400px;
      }
      .mf-mediatio2-section3 .mf-mediatio-info1{
            max-width: 100%;
      }
      .mf-mediatio2-section3 .mf-mediatio-info2{
            max-width: 100%;
      }
}
/*mediation 3 section ends */

/*Mediation 4 section starts*/
.mf-mediation3-section4{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 3rem;
      margin-bottom: 1rem;
}

.mf-mediation3-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-mediation2-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;  
}

.mf-mediation-title-19m01f31 .mf-heading-title{
      font-size: var(--fs-4);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}
/*Mediation 4 section ends*/

/*Mediation 5 section starts*/

.mf-mediation4-section5{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 2rem;
      margin-bottom: 5rem;
}

.mf-mediation4-container{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, auto));
      justify-content: center;
      gap: 3rem;
      background: var(--bs-body-bg);
      border-radius: 5px;
  
}
.mf-mediation-img-card{
      /*padding: 10px;*/
      border-radius: 20px;
      border: .5px solid var(--bs-card-bg);
      overflow: hidden;
      position: relative;
}

.card-img{
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 20px;
}

.mf-mediation-card-body{
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      background: #136a8050;
      border-radius: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;     
}

.mf-mediation-title-43m27f02 .mf-heading-title{
      color: var(--bs-white);
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      text-transform: uppercase;
      text-align: center;
}

.mf-mediation-img-card:hover .mf-mediation-card-body{
      background: #136a8080;
      backdrop-filter: blur(2px);
}

.mf-mediation-img-card:hover .mf-mediation-title-43m27f02{
      transform: scale(1.2);
}
/*Mediation 5 section ends*/

/*Mediation 6 section starts*/
.mf-mediation5-section6{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 5rem;
      margin-bottom: 1rem;
}

.mf-mediation5-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-mediation5-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;  
}

.mf-mediation-title-04m47f83 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
      text-transform: uppercase;
}
/*Mediation 6 section ends*/

/*Mediation 7 section starts*/
.mf-mediation6-section7{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 1rem;
      margin-bottom: 5rem;
}

.mf-mediation6-container{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, auto));
      justify-content: center;
      gap: 3rem;
      background: var(--bs-body-bg);
      border-radius: 5px;
}

.mf-mediation-img-box{
      padding: 10px;
      border-radius: 20px;
      border: .5px solid var(--bs-card-bg);
      background: var(--bs-light1);
      overflow: hidden;
      position: relative;
}

.box-img{
      width: 100%;
      height: 150px;
      object-fit: cover;
      border-radius: 20px;
}

.mf-mediation-title-63m28f06 .mf-heading-title{
      color: var(--bs-primary);
      font-size: var(--fs-9);
      font-weight: var(--fw-700);
      font-family: var(--ff-lato);
      justify-content: center;
      text-align: center;
}
/*Mediation 7 section ends*/

/*Mediation 8 section starts*/

.mf-mediation-info-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 5rem;
      margin-bottom: 5rem;
}

.mf-mediation-info-container{
      display: grid;
      grid-template-columns: 50% 50%;
      justify-content: center;
      gap: 3rem;
      background: var(--bs-rosa);
      border-radius: 5px;
      
}

/*Info content*/
.mf-mediation-info-box{
      position: relative;
      display: flex;
      padding: 15px;
      align-items: center;
      justify-content: center;
      
}

.mediation-info-title{
      color: var(--bs-white);
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 2rem auto;
}


.mf-mediation-info-btnBx{
      position: relative;
      display: flex;
      padding: 15px;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 2rem auto;
      margin-right: 20px;
}

.mf-mediation-info-btn{
      font-size: var(--fs-5);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      line-height: 2.5rem;
      padding: 2rem;
      border-radius: 25px;
}

@media (max-width: 650px){
      .mf-mediation-info-container{
            display:block;
      }
}
@media (max-width: 500px){
      .mediation-info-title{
            font-size: var(--fs-8);
          
      } 
      .mf-mediation-info-btn{
            font-size: var(--fs-7);
            padding: 1rem;
            margin-right: 20px;
      } 
}
/*Mediation 8 section ends*/

/*Mediation 9 section starts*/
.mf-mediation8-section9{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 3rem;
      margin-bottom: 3rem;
}

.mf-mediation8-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-mediation8-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;  
}

.mf-mediation-title-19m24f44 .mf-heading-title{
      font-size: var(--fs-6);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-secondary);
      line-height: 2;
      text-align: center;
     
     
}
/*Mediation 9 section ends*/

/*contact section starts*/

.mf-mediation-contact-section-27m10f36{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5rem;
      padding-bottom: 5rem;
      background: var(--bs-secondary);
      overflow: hidden;   
}

.mf-mediation-contact-container{
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;   
     
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      /*background: pink;*/
}

.mf-mediation-contact-content{
      padding-bottom: 2rem;
}

.mediation-info-title-26e5f30c .mf-heading-title{
      font-size: var(--fs-2);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-white);
      line-height: 1.8;
}

.mediation-info-text-22a7b451 .mf-text-description{
      font-size: var(--fs-4);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.8;
}

.mediation-info-text-32m10f63 .mf-text-description{
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-light);
      line-height: 1.8;
}

@media (max-width: 800px){
      .mf-mediation-contact-section-27m10f36{
            padding: 1rem 1rem !important;
      }

      .mediation-info-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-4);
      }
      .mediation-info-text-22a7b451 .mf-text-description{
            font-size: var(--fs-6);
      }
      .mediation-info-text-32m10f63 .mf-text-description{
            font-size: var(--fs-8);
      }
}

@media (max-width: 546px){
      .mf-mediation-contact-section-27m10f36{
            padding: 1rem 1rem !important;
      }

      .mediation-info-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-5);
      }
      .mediation-info-text-22a7b451 .mf-text-description{
            font-size: var(--fs-7);
      }
      .mediation-info-text-32m10f63 .mf-text-description{
            font-size: var(--fs-9);
      }
}

.mf-mediation-contact-btn{
      margin-top: 2rem;
      padding: 2rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-800);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      align-items: center;
      color: var(--bs-rosa);
}

@media (max-width: 546px){
      .mf-mediation-contact-btn{
            font-size: var(--fs-9);
            font-weight: var(--fw-600);
            letter-spacing: 0px;  
      }
}
/*contact section ends*/

