/*hero section start*/
.section-img-bg-25m43f62{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_blog_1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 15rem;
      padding-bottom: 10rem;
      overflow: hidden;
}

@media (max-width: 1028px){
   .section-img-bg-25m43f62{
   padding-top: 25rem;
   padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-25m43f62{
      padding-top: 20rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-25m43f62{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.p-0{
      padding: 0 !important;
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
      
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/
      
}

/*A hero text*/

 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}


.title-73m19f57 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .title-73m19f57 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*Blog grid section starts*/
.mf-blogs-section{
   background: var(--bs-body-bg);
   margin-top: 3rem;
}

.post-filter{
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 1.5rem;
   margin-top: 2rem !important;
   margin-bottom: 5rem !important;
}

.filter-item{
   font-size: var(--fs-8);
   font-weight: var(--fw-500);
   color: var(--bs-rosa);
   cursor: pointer;
}

.active-filter{
   background: var(--bs-primary);
   color: var(--bs-white);
   padding: 4px 10px;
   border-radius: 4px;
   width: max-content;
}


/*Posts*/
.post{
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(300px, auto));
   justify-content: center;
   gap: 3rem;
}

.post-box{
   background:var(--bs-card-bg);
   box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
   padding: 15px;
   border-radius: 0.5rem;
   border: 0.5px solid var(--bs-card-bg);
}

.post-img{
   width: 100%;
   height: 200px;
   object-fit: cover;
   border-radius: 0.5rem;
}


.category{
   font-size: var(--fs-10);
   font-weight: var(--fw-500);
   text-transform: uppercase;
   color: var(--bs-rosa);
}

.post-title{
   font-size: var(--fs-7);
   font-weight: var(--fw-600);
   color: var(--bs-primary);
   /*to remain title in 2 lines*/
   display: -webkit-box;
   -webkit-line-clamp: 2;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.post-date{
   display: flex;
   font-size: var(--fs-10);
   text-transform: uppercase;
   font-weight: var(--fw-400);
   margin-top: 4px;
}

.post-description{
   font-size: var(--fs-9);
   line-height: 2rem;
   margin: 5px 0 10px;
   /*to remain title in 2 lines*/
   display: -webkit-box;
   -webkit-line-clamp: 3;
   -webkit-box-orient: vertical;
   overflow: hidden;
}

.profile{
   display: flex;
   align-items: center;
   gap: 10px;
}

.profile-img{
   width: 35px;
   height: 35px;
   border-radius: 50%;
   object-fit: cover;
   object-position: center;
   border: 2px solid var(--bs-secondary);
}

.profile-name{
   font-size: var(--fs-10);
   font-weight: var(--fw-500);
   color: var(--bs-secondary);
}


@media (max-width:680px){
   .post-filter{
      display: block;
      line-height: 1.5;
   }
}
/*Blog grid section ends*/
