 
 @import url('https://fonts.googleapis.com/css2?family=Baloo+Bhai+2:wght@400..800&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root{
      
         /*colors*/
      --bs-primary:#136a80;
      --bs-secondary:#81b2bb;
      --bs-rosa:#f84b70;
      --bs-light:#444242;
      --bs-light1:#f5eee7;
      --bs-body-bg:#fafafa;
      --bs-card-bg: #dfe9eb;
      --bs-white:#fff;
      --bs-black:#000;

      /*shadow*/
      --box-shadow:0 .5rem 1rem rgba(0,0,0,.1);

       /*border*/
       --border:.2rem solid rgba(0,0,0,.1);
       --outline:.1rem solid rgba(0,0,0,.1);
       --outline:hover:.2rem solid var(--black);
 
       --transition-1: 0.25s ease-in-out;

      /*typography*/

      --fs-1: 5rem;
      --fs-2: 4.5rem;
      --fs-3: 3.5rem;
      --fs-4: 3rem;
      --fs-5: 2.5rem;
      --fs-6: 2.2rem;
      --fs-7: 2rem;
      --fs-8: 1.8rem;
      --fs-9: 1.5rem;
      --fs-10: 1.3rem;
      --fs-11: 1rem;
      
      --fw-800: 800;
      --fw-700: 700;
      --fw-600: 600;
      --fw-500: 500;
      --fw-400: 400;

      --ff-baloo: 'Baloo Bhai 2',sans-serif;
      --ff-lato: 'Lato', sans-serif;

}

li{ list-style: none;}

a{ color: inherit; text-decoration: none;}

span,
input,
label,
select,
button,
ion-icon{ display: block;}


button,
ion-icon{pointer-events: none;}


*
{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Baloo Bhai 2', 'Lato', sans-serif;
    outline: none; border: none;
    text-decoration: none;
    text-transform: none;
    transition: all .2s linear;

}


*,
*::after,
*::before{
      box-sizing: border-box;
}

html{
      font-size: 62.5%;
      overflow-x: hidden;
      scroll-behavior: smooth;
      /*scroll-padding-top: 7rem; 
      scrollbar-color: #777 #555;*/
      text-size-adjust: 100%; 
      line-height: 1.2;
      font-family: sans-serif;
}


.mf-page{
      display: block;
}

body{
      background-color: var(--bs-body-bg);
      color: var(--bs-light);
}
  
.section{
      padding: 2rem 9%;
      position: relative;
}

.container{
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
}

div{
      display: block;     
}


h1{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-5);
      font-weight: var(--fw-500);
      line-height: 1.2;
}

h2{
      color:var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      line-height: 1.2;
}

h3{
      color:var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-500);
      line-height: 1.2;  
}

p{
      color:var(--bs-light);
      font-family: var(--ff-lato);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      line-height: 1;   
}


h1,
h2{
      display: block;
      margin-block-start: 0.83;
      margin-block-end: 0.83;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
}

h3,
p{
      display: block;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
}

h1,
h2,
h3{
      margin-top: 0.5rem;
      margin-bottom: 1rem;
}

p{
      margin-top: 0;
      margin-bottom: 0.9rem;
}


.btn{
      position: relative;
      display: inline-block;
      font-family: var(--ff-baloo);
      padding: .8rem 2rem;
      font-size: var(--fs-8);
      font-weight: var(--fw-700);
      letter-spacing: 0.7px;
      line-height: 2rem;
      border-radius: 1.5rem;
      /*border: .2rem solid var(--bs-primary);*/
      background: var(--bs-light1);
      /*max-width: max-content;*/
      align-items: center;
      color: var(--bs-rosa);
      text-transform: uppercase;
      cursor: pointer;
      transition: all .2s linear;
}

.btn:hover{
      background: var(--bs-primary);
      color: var(--bs-white);
}


.p-0{
      padding: 0 !important;
}

.w-1{
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
}

.homepage{
      margin-top: 150px;
}

/*Header starts*/
.header{
    position: fixed;
    top: 0; left: 0; right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /*padding: 1rem 5%;*/
    background-color: var(--bs-white);
    box-shadow: var(--box-shadow);
    align-items: center;
    justify-content: center;
}



.header .logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.header .logo img{
    width: 20em;
}


.header .navbar a{
    font-size: var(--fs-8);
    font-weight: 600;
    margin: 0 1rem;
    color: var(--bs-primary);
    font-family: var(--ff-baloo);
    padding: .5rem;
    border-radius: .5rem;
}

.header .navbar a:hover{
    /*background: var(--bs-primary);*/
    color: var(--bs-rosa);
}

.header .icons div{
    height: 4.5rem;
    width: 4.5rem;
    line-height: 4.5rem;
    border-radius: .5rem;
    background: var(--bs-card-bg);
    color: var(--bs-primary);
    font-size: 2rem;
    margin-left: .3rem;
    cursor: pointer;
    text-align: center;

    /*display:flex;*/
}

.header .icons div:hover{
    background: var(--bs-primary);
    color: var(--bs-white);
}

#menu_btn{
    display: none; /*does not see*/
}

/* Alap stílusok */
.navbar {
      display: flex;
      list-style: none;
      justify-content: space-between;
      align-items: center;
      
      /*gap: 20px;*/
    }
    
    .navbar nav {
      display: flex;
    }
    
    /* A lenyíló menü gomb stílusai 
    .dropbtn {
      background-color: #ffffff;
      cursor: pointer;
      font-weight: 600;
      margin: 0 1rem;
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      padding: .5rem;
      border-radius: .5rem;
    }*/

    .dropdown{
      font-size: var(--fs-8);
      font-weight: 600;
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      position: relative;
      display: inline-block;
    }
    
    /* A lenyíló menü tartalma */
    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #ffffff;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      z-index: 1;
      padding-top: 10px;
      min-width: max-content;
    }
    
    /* Ha az egérrel ráhúznak a lenyíló menüre, jelenítse meg a tartalmat */
    .dropdown:hover .dropdown-content {
      display: block;
    }
    
    /* A lenyíló menü tartalmának stílusai */
    .dropdown-content a {
      color: #333333;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }

    
    /* A lenyíló menü gomb stílusai */
    .dropbtn {
      background-color: #ffffff;
      color: #333333;
      padding: 5px 1px;
      font-size: 16px;
      border: none;
      cursor: pointer;
    }
    
    /* Ha a lenyíló menü nyitva van, jelenítse meg a tartalmat */
    .dropdown-content a {
      color: #333333;
      padding: 12px 16px;
      text-decoration: none;
      display: block;
    }
    
    /* Ha az egérrel a lenyíló menüre kattintanak, jelenítse meg a tartalmat */
    .dropdown:hover .dropdown-content {
      display: block;
    }

@media (max-width: 1200px){
    html{
          font-size: 55%;
    }
    .header{
          padding: 2rem
    }
    .section{
          padding: 2rem;
    }
}

@media (max-width: 1050px){
      #menu_btn{
          display: inline-block;
    }
    .navbar{
          position: absolute;
          top: 125px; 
          /*width: 30rem;  cgp*/
          box-shadow: var(--box-shadow);
          border-radius: .5rem;
          background: var(--bs-white); 
          justify-content: left;
          display: none;
          flex-direction: column;
          width:100%;
          
    }

    .header .logo img{
      width: 35em;
  }

    .header .navbar a{
      font-size: var(--fs-6);
      font-weight: 600;
      margin: 0 1rem;
      padding-top: 5px;
      border-radius: .5rem;
}

    .navbar.open {
      display: flex;
    }
  
    .icons .fas.fa-bars {
      display: block;
      cursor:pointer;
    }

    .header .icons div{
      height: 5.5rem;
      width: 5.5rem;
    }
    .dropbtn {
      padding: 20px 15px;
      font-size: 16px;
      border: none;
      cursor: pointer;
      margin-top: 10px;
    }

    .dropdown-content {
      display: none;
      position: absolute;
      background-color: #ffffff;
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
      z-index: 1;
      
    }
    .dropdown a{
      font-size: var(--fs-8);
      font-weight: 600;
      margin: 1rem 1rem;
      color: #f84b70;
      /*color: var(--bs-primary);*/
      font-family: var(--ff-baloo);
      padding: .5rem;
      border-radius: .5rem;
    }
  }
@media (max-width: 500px){
      .header .logo img{
            width: 25em;
        }
}
/*
@media (max-width: 907px){

    .header{
          padding: 2rem
    }

   
    
    
    .header .navbar.active{
          right: 2rem;
          transition: .4s linear;
    }
    
    .header .navbar a{
          font-size: 2rem;
          margin: 2rem 2.5rem;
          display: block;
    }
    .section{
          padding: 0 4%;
    }
}

@media (max-width: 450px){
    html{
          font-size: 50%;
    }
}

@media (max-width: 310px){
    .header{
          padding: .5rem;
    }
    .header .logo img{
          width: 20rem;
    }
}*/
/* Header ends*/


/*contact first section starts 

.mf-contact-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 3rem;
      margin-bottom: 3rem;
}

.mf-contact-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-contact-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      text-align: left;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.1);
}
.mf-contact-title-86m12f24 .mf-heading-title{
      font-size: var(--fs-2);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}*/
/*contact first section ends */


.mf-contact1-section2{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-secondary);
      overflow: hidden;
      margin-top: 2rem;
}

.mf-contact1-container{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, auto));
      justify-content: center;
      gap: 3rem;
}
/*Info content*/
.mf-contact-info-box{
      background-color: rgba(255,255,255,0.1);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      padding: 15px;
      align-items: center;
      justify-content: center;
      

}

.contact-title{
      color: var(--bs-primary);
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      margin: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.contact-description{
      color: var(--bs-primary);
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      margin: 2rem 0;
      padding-top: 2rem;
      line-height: 1.5;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.contact-text{
      color: var(--bs-primary);
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      margin: 2rem 0;
      padding: 2rem 0;
      line-height: 1.5;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.contact-info{
      border-radius: 20px;
      box-shadow: var(--box-shadow);
}


.contact-info h3{
      display: flex;
      align-items: center;
      font-size: var(--fs-7);
      font-weight: var(--fw-400);
      color: var(--bs-primary);
      padding: 1rem 3rem;
      
}

.contact-info h3 i{
      padding-right: 2rem;
      color: var(--bs-light);
}

/* Landing Page start */

.home-section-img-bg-1m1f0101{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf-home-bg-1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 25rem;
      padding-bottom: 10rem;
      overflow: hidden;
     
}


@media (max-width: 450px){
      .home-section-img-bg-1m1f0101{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .home-section-img-bg-1m1f0101{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}


.mf-home-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: orange;*/
     
}


.mf-home-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 10px;
      /*background: #333;*/
      
}

@media (min-width: 768px){
      .mf-home-column-wrap .mf-col-100{
            width: 100%;
      } 
      
}

.mf-home-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: #000;*/
      
}

.home-title-7e1f8217 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-4);
      font-weight: var(--fw-600);
}


 .mf-text-description{
      color: var(--bs-light1);
      font-size: var(--fs-9);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
      
}

.home-text-71642346 .mf-home-text-description{
      color: var(--bs-white);
      font-size: var(--fs-7);
      font-family: var(--ff-lato);
      font-weight: var(--fw-500);
      padding-top: 3rem;
}



@media (max-width: 450px){
      .mf-home-heading-title{
      font-size: var(--fs-8);
      }

      .mf-home-text-description{
      font-size: var(--fs-10); 
      } 
}


.w-1{
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
}


/*home-about section starts*/


.mf-home-about-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: var(--bs-body-bg);
      background-size: cover;
      padding-top: 5rem;
      padding-bottom: 5rem;
      overflow: hidden;
      /*background: palegreen;*/
}

.mf-home-about-section-responsive{
      display: none;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: var(--bs-body-bg);
      background-size: cover;
      padding-top: 2rem;
      padding-bottom: 5rem;
      overflow: hidden;
      /*background: palegreen;*/
}

.mf-home-about-container-responsive{
      display: column;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: palevioletred;*/

}

.mf-home-about-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: palevioletred;*/

}

.padd-15{ /* modositva 15-ről 1-re megszűnt a ficergés*/
      padding-left: 1px;
      padding-right: 1px;
}

.mf-home-about-row{
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;

}

.mf-home-about-section .mf-home-about-info{
      flex: 0 0 60%;
      max-width: 60%;
}

.mf-home-title-75f3e671 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      line-height: 2;
      margin: 1rem 0;
      /*margin-top: 1rem;
      margin-bottom: 3rem;*/
}

.mf-home-title-41f2m498 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-5);
      font-weight: var(--fw-500);
      line-height: 1.5;
      margin: 1rem 0;
}


.mf-home-about-text-43b5e218 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 0.1rem 0;
}

.mf-home-about-title-82f3e673 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-500);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.2;
      margin: 1rem 0;
}

/*button- start*/
.mf-home-about-btn{
      position: relative;
      display: flex;
      width: 200px;
      height: 40px;
      padding-left: 40px;
      justify-content: center;
      align-items: center;
      border-radius: 60px;
      border-top: 1px solid rgba(255,255,255,0.35);
      border-left: 1px solid rgba(255,255,255,0.35);
      background: var(--bs-rosa);
      color: var(--bs-white);
      letter-spacing: 0.7px;
      margin: 3rem;
   
}

.mf-home-about-btn:hover{
      background: var(--bs-secondary);
      color: var(--bs-primary);
      padding-left: 0;
      padding-right: 40px;
}

.mf-home-about-btn span{
      position: absolute;
      left: 5px;
      width: 30px;
      height: 30px;
      background: var(--bs-secondary);
      border-radius: 50%;
      transition: .5s ease ease-in-out;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--bs-white);
      font-size: 1.5em;
}

.mf-home-about-btn:hover span{
      left: calc(100% - 55px);
}

/*about button end*/


/*about img start*/
.mf-home-about-section .mf-home-about-img{
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
      position: relative;
      /*background: chocolate;*/
}

.mf-home-about-img::after{
      content: '';
      position: absolute;
      height: 80px;
      width: 80px;
      border-bottom: 10px solid var(--bs-primary);
      border-right: 10px solid var(--bs-primary);
      right: -40px;
      bottom: 20px;
}

.mf-home-about-img::before{
      content: '';
      position: absolute;
      height: 80px;
      width: 80px;
      border-top: 10px solid var(--bs-primary);
      border-left: 10px solid var(--bs-primary);
      left: -10px;
      top: -30px;
}

.mf-home-about-section .mf-home-about-img img{
      margin: auto;
      border-radius: 5px;
      height: 600px;
      
}
@media (max-width: 1310px){
      .mf-home-about-img::before{
            content:none;
      }
      .mf-home-about-img::after{
            content: none;
      }
      .mf-home-about-section .mf-home-about-img img{
            height: 400px;
      }

}
@media (max-width: 890px){

      .mf-home-about-section{
            display: none;
      }
      .mf-home-about-section-responsive{
            display: flex;
      }

      .home-section-img-bg-1m1f0101{
            margin-top: 50px; 
            }  

      .mf-home-about-section-responsive .mf-home-about-img{
            flex: 0;
            text-align: center;
            position: relative;
            /*background: chocolate;*/
      }

      .mf-home-about-section-responsive .mf-home-about-img img{
            padding-bottom: 20px;
            border-radius: 5px;
            height: 350px;
            
            
      }  
      .mf-home-about-img::after{
            display: none;
      }
      .mf-home-about-img::before{
            display: none;
      }
      .mf-home-about-section-responsive .mf-home-about-img img {
            margin: 0;
            border-radius: 5px;
            height: 600px;
            
      }
}

@media (max-width: 560px){
      .mf-home-about-section-responsive .mf-home-about-img img {
            height: 350px;
            max-width: 300px;
      }
}
/*home-about section ends*/

/*Home-service text -start*/
.mf-section-service{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 2rem 2rem;
      background-color: transparent;
      background-size: cover;
      background: var(--bs-body-bg);
      overflow: hidden;
}

.mf-home-service1-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center; 
      margin-top: 0.1rem;
}


.mf-home-service-title-65m32f96 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-2);
      font-weight: var(--fw-800); 
}
/*Home-service text- ends*/



/*home-sercice-content starts

.mf-section-service-content{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      background-color: var(--bs-body-bg);
      background-size: cover;
      
}

.mf-home-service-container{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      padding: 1rem;
      margin-right: 30px;
      margin-left: 30px;
      
}

@media (max-width: 1200px){
      .mf-home-service-container{
            grid-template-columns: 1fr 1fr;
            justify-content: center;
            margin-right: 30px;    
      }

      .mf-col-md-33{
            max-width: 100%;
      }
}

@media (max-width: 900px){
      .mf-home-service-container{
            grid-template-columns: 1fr;
            margin-left: 1rem !important;
            margin-right: 1rem !important;
            
      }
      .mf-col-15{
            max-width: 100%;
      }
}

.pt-5{
      padding-top: 3rem !important;
}

.px-0{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
}

@media (min-width: 992px){
      .py-lg-5{ 
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
      }
      .px-0{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
      }
}

@media (min-width: 540px){
      .py-lg-5{ 
            padding-bottom: 2rem !important;
            padding-top: 2rem !important;
      }
      .px-0{
            padding-left: 1rem !important;
            padding-right: 1rem !important;
      }
}

.mf-col-15{
      position: relative;
      margin: 2rem;
      padding: 2rem;
      width: 85%;
      height: 400px;
      text-align: center;
      background: linear-gradient(-20deg, #dfe9eb 65%, #136a80 10%);
      box-shadow: var(--box-shadow);
      border-top-right-radius: 10%;
      border-bottom-left-radius: 10%;
      cursor: pointer;
      overflow: hidden;
}

@media (max-width: 425px){
      .mf-home-service-bkg{
            margin: -15px;
      }
}

.p-2{
      padding: 0.5rem !important;
}

#profile1{
      width: 160px;
      border-radius: 50%;
      border: 1px solid var(--bs-primary);
      margin: 60px auto;
      position: relative;
      z-index: 2;
      transition: .4s;
}

.mf-col-15:hover{
      background: var(--bs-light1);
      
}

.mf-col-15:hover #profile1{
      transform: translate(-110px, -30px);
      width: 100px;
}

.mf-widget-home-service-heading-title{
      margin: 0 20px;
}

.mf-col-15:hover .mf-widget-home-service-heading-title{
      margin: -30px;
}

.home-service-title-29fe5372 .mf-home-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-5);
      font-weight: var(--fw-600); 
      transform: translateY(-40px); 
}

.mf-col-15:hover .home-service-title-29fe5372 .mf-home-heading-title{
      transform: translate(0px, -50px);
      color: var(--bs-rosa);
      font-size: var(--fs-7);
      
}


.mf-home-service-bkg{
      height: 120px;
      width: 100%;
      background: var(--bs-primary);
      position: absolute;
      z-index: 2;
      top: 0px;
      margin: -5px;
      transition: .5s;
      
}

.mf-col-15:hover .mf-home-service-bkg{
      height: 90px;
      top: 335px;
      background: var(--bs-secondary);
}

.mf-widget-home-service-text-description{
      margin-top: -50px;
      width: 100%;
      padding: 1rem;
      opacity: 0;
      
}


.home-service-text-31f6b357 .mf-home-sevice-text-description{
      color: var(--bs-light);
      font-family: var(--ff-lato);
      font-size: var(--fs-8);
      font-weight: var(--fw-400); 
      margin: 0px auto;
      padding: 2rem;
      transition: .5s;
}

.mf-col-15:hover .mf-widget-home-service-text-description{
      opacity: 1;
}
.mf-col-15:hover .home-service-text-31f6b357 .mf-home-service-text-description{
      transform: translateY(220);
      font-size: var(--fs-8);
      line-height: 1.5;
      border-top: 2px solid #81b2bb;
      

}

.mf-home-service-soc{
      position: absolute;
      display: flex;
      justify-content: center;
      bottom: 0px;
      width: 100%;
}
.mf-home-service-btn{
      margin: 2rem;
      padding: 2rem;
      font-size: var(--fs-9);
      font-weight: var(--fw-500);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      box-shadow: var(--box-shadow);
      align-items: center;
      color: var(--bs-rosa);
      opacity: 1;
      
      
}

.mf-col-15:hover .mf-home-service-btn{
      opacity: 1;
      z-index: 2;
      margin: 1rem;
      padding: 2rem;
}
Home-service-content ends*/

/*Home-online starts*/
.mf-home-online-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 10rem;
      padding-bottom: 5rem;
      background: var(--bs-secondary);
      overflow: hidden;
}

.mf-home-online-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: palevioletred;*/

}

.padd-25{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-home-online-row{
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;

}


.mf-home-online-section .mf-home-online-img{
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
      position: relative;
      /*background: chocolate;*/
}

.mf-home-online-section .mf-home-online-img img{
      margin: auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 30em;
}


.mf-home-online-section .mf-home-online-info{
      flex: 0 0 60%;
      max-width: 60%;
}


.mf-home-online-title-8e4f6721 .mf-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      justify-content: center;
      align-items: center;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-home-online-title-41f2m502 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-500);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}



.mf-home-online-text-50m5e218 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-home-online-imgbox-small{
      display:none;
}

/*button- start*/

.mf-home-online-btn-box{
      text-align: center;
}
.mf-home-online-btn{
      margin: 2rem;
      padding: 2rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      color: var(--bs-rosa);
      box-shadow: var(--box-shadow);
      align-items: center;   
}
/*button end*/
@media(max-width: 982px){
      .mf-home-online-section .mf-home-online-img img{
            height: 20em;
      }
      
}
@media(max-width: 800px){
      .mf-home-online-section .mf-home-online-info{
            max-width: 100%;
      }

      .mf-home-online-imgbox-small{
            display:contents;
      }

      .mf-home-online-imgbox-large{
            display:none;
      }

      .mf-home-online-section .mf-home-online-img{
            max-width: 100%;
      }

      .mf-home-online-section .mf-home-online-img img{
            height: 30em;
      }

      .mf-home-online-row{
            display:block;      
      }
}

/*Home-online ends*/

/*Home FAQ section start*/
.mf-home-faq-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 5rem;
      padding-bottom: 5rem;
      overflow: hidden;
      /*background: navajowhite;*/
}

.mf-home-faq-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
}

.mf-home-faqbtn-box{
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 2rem;
}
.mf-home-faq-btn{
      margin: 2rem;
      padding: 5rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 2rem;
      border-radius: 2rem;
      border: .2rem solid var(--bs-rosa);
      background: var(--bs-light1);
      box-shadow: var(--box-shadow);
      align-items: center;   
      color: var(--bs-rosa);
}

.mf-home-faq-btn:hover{
      background: var(--bs-primary);
      color: var(--bs-white);
      border: none;
}
/*Home FAQ section end*/








/*Blog section start*/
.mf-home-blog-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 3rem;
      margin-bottom: 3rem;
}

.mf-blog-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-blog-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      text-align: left;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0,0,0,0.1);
}

.mf-home-blog-text-56m37f39 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-secondary);
      line-height: 1.2;
      margin-left: 2rem;
      text-transform: uppercase;
}

.mf-home-blog-title-27m46f81 .mf-heading-title{
      font-size: var(--fs-2);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}
/*Blog section ends*/







/*Posts section starts*/
.mf-posts-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: url(../image/mf_home_blog_1.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      overflow: hidden;
      margin-top: 2rem;
}

.post{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, auto));
      justify-content: center;
      gap: 3rem;
      
}
   
.post-box{
      background:var(--bs-card-bg);
      box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
      padding: 15px;
      border-radius: 0.5rem;
      border: 0.5px solid var(--bs-card-bg);
}
   
.post-img{
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 0.5rem;
}
   
   
.category{
      font-size: var(--fs-10);
      font-weight: var(--fw-500);
      text-transform: uppercase;
      color: var(--bs-rosa);
}
   
.post-title{
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      color: var(--bs-primary);
      /*to remain title in 2 lines*/
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
}
   
.post-date{
      display: flex;
      font-size: var(--fs-10);
      text-transform: uppercase;
      font-weight: var(--fw-400);
      margin-top: 4px;
}
   
.post-description{
      font-size: var(--fs-9);
      line-height: 2rem;
      margin: 5px 0 10px;
      /*to remain title in 2 lines*/
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
}
   
.profile{
      display: flex;
      align-items: center;
      gap: 10px;
}
   
.profile-img{
      width: 35px;
      height: 35px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      border: 2px solid var(--bs-secondary);
}
   
.profile-name{
      font-size: var(--fs-10);
      font-weight: var(--fw-500);
      color: var(--bs-secondary);
}
/*Posts section ends*/









/*Home-cont1-section starts*/

.mf-home-cont1-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 5rem;
      padding-bottom: 2rem;
      overflow: hidden;
      /*background: navajowhite;*/
}

.mf-home-cont1-content-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
}

.mf-home-cont-title-53f2m503 .mf-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-home-cont-text-57f5e220 .mf-text-description{
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}
/*Home-cont1-section ends*/








/*Home-cont2-section starts*/
.mf-home-cont-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: var(--bs-body-bg);*/
      padding-top: 2rem;
      padding-bottom: 5rem;
      overflow: hidden;
      /*background: navajowhite;*/
}

.mf-home-cont-container{
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      /*position: relative;*/
      margin: 2rem auto;
      align-items: center;
      justify-content: center;
      /*background: palevioletred;*/
}
.mf-home-cont-row{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: row;
}

.mf-home-cont-row .mf-home-cont-card{
      background: var(--bs-secondary);
      width: 20em;
      margin: 1.25em;
      padding: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: var(--box-shadow);
      border: 10px;
      border-radius: 5px;
}

.mf-home-cont-card .mf-home-cont-icon{
      color: var(--bs-primary);
      font-size: 4em;
      text-align: center;
      transition: transform 0.5s ease;
}

.mf-home-cont-card:hover .mf-home-cont-icon{
      transform: translateY(-10px);
}


.mf-home-cont-row .mf-home-cont-card .mf-home-cont-info{
      text-align: center;
} 

.mf-home-cont-card .mf-home-cont-info .adress{
      color: var(--bs-white);
      font-size: var(--fs-6);
      font-weight: var(--fw-500);
      letter-spacing: 0.5px;
      margin: 10px 0;
}

.mf-home-cont-card .mf-home-cont-info .adress-text{
      color: var(--bs-light);
      font-weight: var(--fw-400);
      letter-spacing: 0.7px;
      line-height: 1.2;
}


.mf-home-cont-card .mf-home-cont-info .phone{
      color: var(--bs-white);
      font-size: var(--fs-6);
      font-weight: var(--fw-500);
      letter-spacing: 0.5px;
      margin: 10px;
}

.mf-home-cont-card .mf-home-cont-info .phone-text{
      color: var(--bs-light);
      font-weight: var(--fw-400);
      letter-spacing: 0.7px;
      line-height: 1.2;
}

.mf-home-cont-card .mf-home-cont-info .email{
      color: var(--bs-white);
      font-size: var(--fs-6);
      font-weight: var(--fw-500);
      letter-spacing: 0.5px;
      margin: 10px;
}

.mf-home-cont-card .mf-home-cont-info .email-text{
      color: var(--bs-light);
      font-weight: var(--fw-400);
      letter-spacing: 0.7px;
      line-height: 1.2;
}


.mf-home-cont-card .mf-home-cont-info .social{
      color: var(--bs-white);
      font-size: var(--fs-6);
      font-weight: var(--fw-500);
      letter-spacing: 0.5px;
      margin: 10px;
}

.mf-home-cont-card .mf-home-cont-info .social-text{
      color: var(--bs-light);
      font-weight: var(--fw-400);
      letter-spacing: 0.7px;
      line-height: 1.2;
}






/*home cont3 section start*/

.mf-home-cont2-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

.mf-home-cont2-container{
      position: relative;
      display: flex;
      flex-direction: column;
      /*background: #444242;*/
}

.mf-home-message-title{
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: var(--ff-baloo);
      font-size: var(--fs-4);
      font-weight: var(--fw-700);
      color: var(--bs-primary);
}

.mf-home-message-title span{
      color: var(--bs-secondary);
      margin-left: 10px;
}
.mf-home-cont2{
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 30px 100px;
      /*background: palevioletred;*/
}

.mf-home-cont2:after{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      filter: blur(50px);
      z-index: -1;
}


.mf-home-contact-form-box{
      max-width: 850px;
      display: grid;
      grid-template-columns: repeat(2,1fr) ;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #fff;
      box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.19);  
}

.left{
      padding: 25px 40px;
      height: 100%;
}

.mf-contact-img{
      position: relative;
      justify-content: center;
      align-items: center;
}

.mf-contact-img img{
      margin: 2rem 1rem;
      height: 35em;
}




.right{
      padding: 25px 40px;
}

.mf-home-contact-input-box{
      outline: none;
      margin-bottom: 22px;
      transition: 0.3s;
      color: var(--bs-light);
      text-align: left;
}

 .mf-home-contact-input-box input,
 .mf-home-contact-input-box textarea{
      border: .1rem solid var(--bs-secondary);
      border-radius: 1rem;
      width: 300px;
      padding: 1rem 1.5rem;
      font-size: var(--fs-9);
      font-weight: var(--fw-400);
      outline: none; 
      resize: none;
     
}

.mf-home-contact-input-box .send-btn{
      width: 40%;
      color: var(--bs-white);
      background: var(--bs-primary);
      display: inline-block;
      font-size: var(--fs-8);
      font-weight: var(--fs-5);
      text-transform: uppercase;
      letter-spacing: 2px;
      border: none;
      cursor: pointer;
      transition: 0.5s ease;
}

.send-btn:hover{
      background: var(--bs-secondary);
}

@media (max-width:768px) {
     .left{
       display: none;
     } 
     .mf-home-contact-input-box .send-btn{
      width: 100%;
     }
     .mf-home-cont2{
        padding: 5px;
      /*background: palevioletred;*/
      }
}


/*home cont3 section ends*/









/*back To Top button start*/
.back-to-top{
      position: fixed;
      bottom: 20px;
      right: 20px;
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bs-body-bg);
      border: 2px solid var(--bs-rosa);
      border-radius: 50%;
      text-decoration: none;
      transition: 0.2s ease-out;
      cursor: pointer;
}

.back-to-top span{
      color: var(--bs-rosa);
      font-size: 20px;
      justify-content: center;
      transition: 0.2s ease-out;
}

.back-to-top:hover{
      background-color: var(--bs-rosa);
}

.back-to-top span:hover{
      color: var(--bs-white);
      transform: translateY(-4px);
}


@media (max-width:992px) {
      .back-to-top{
            width: 40px;
            height: 40px;
      } 
      .back-to-top span{
            font-size: 15px;
      }
}

@media (max-width:768px) {
      .back-to-top{
            width: 32px;
            height: 32px;
      } 
      .back-to-top span{
            font-size: 12px;
      }
}
/*back To Top button ends*/



/* Landing Page end */


/*Form box-starts*/
.mf-contact-form-box{
      /*background-color: rgba(255,255,255,0.1);*/
      background-color: var(--bs-primary);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      padding: 15px;
      align-items: center;
      justify-content: center;
}

.mf-contact-form-box .mf-cont-title-21m74f59 .mf-heading-title{
      color: var(--bs-white);
      font-size: var(--fs-5);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      margin-bottom: 2rem;
      letter-spacing: 0.7px;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.mf-contact-form-box .mf-home-contact-input-box{
      position: relative;
      width: 100%;
      margin-bottom: 20px;
}

.mf-contact-form-box .mf-home-contact-input-box input,
.mf-contact-form-box .mf-home-contact-input-box textarea{
      color: var(--bs-light);
      width: 100%;
      padding: 1rem 1.5rem;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      outline: none;
      border: .1rem solid var(--bs-secondary);
      border-radius: 1rem;
      resize: none;
}

.mf-contact-form-box .mf-home-contact-input-box .send-btn{
      color: var(--bs-white);
      background: var(--bs-secondary);
      display: inline-block;
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      text-transform: uppercase;
      letter-spacing: 2px;
      width: 30%;
      border-radius: 25px;
      border: none;
      cursor: pointer;
      transition: 0.5s ease;
}

.mf-contact-form-box .mf-home-contact-input-box .send-btn:hover{
      background: var(--bs-rosa);
}

/*Contact section ends*/

/*Footer starts*/

.copyright{
      background: var(--bs-body-bg);
      text-align: center;
      justify-content: center;
      padding: 2rem;
      background-size: cover;
      background-position: center;
 }

 .copyright .credit{
      text-align: center;
      font-size: var(--fs-9);
      font-weight: var(--fw-600);
      color: var(--bs-primary);
 }
/*Footer ends*/

