/* Hero section starts*/
.section-img-bg-23m51f87{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_valas_mediacio.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .section-img-bg-23m51f87{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-23m51f87{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-23m51f87{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 2rem;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/  
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mediation-title-10m32f57 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .mediation-title-10m32f57 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/* Hero section ends*/

/*Divorce first section starts*/
.mf-dmediation-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 2rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-dmediation-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-dmediation-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-dmediation-section .mf-dmediation-info{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-dmediation-text-04m06f54 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

.mf-dmediation-text-04m09f70 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

.mf-dmediation-text-04m10f43 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 1rem auto;
      padding-bottom: 1rem;
}
.mf-dmediation-text-04m10f43 .mf-text-description b{
      font-family: var(--ff-lato);
}

/*img*/
.mf-dmediation-section .mf-dmediation-img{
      flex: 0 0 40%;
      max-width: 100%;
      position: relative;
      text-align: center;
      /*background: chocolate;*/
}

.mf-dmediation-section .mf-dmediation-img img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 30em;
}

@media (max-width: 650px){
      .mf-dmediation-row{
            display: grid;
            width: 400px;
      }
      .mf-dmediation-section .mf-dmediation-info{
            max-width: 100%;
      }
}
/*Divorce first section ends*/

/*Divorce second section starts*/
.mf-dmediation1-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-dmediation1-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-dmediation-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 1rem;
      margin-bottom: 0rem; 
}

.mf-dmediation-title-21m58f95 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-dmediation-content-text{
      position: relative;
      width: 100%;
}

.mf-dmediation-content-text .mf-dmediation-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-dmediation-list .dmediation-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-dmediation-list .dmediation-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-dmediation-list .dmediation-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

.mf-dmediation-text-25m67f30 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

@media (max-width: 650px){
      .mf-dmediation-content-text .mf-dmediation-list{
            margin: 0.1rem;
      }
}
/*Divorce second section ends*/

/*divorce 3 section starts*/
.mf-dmediation2-section3{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-secondary);
      margin-top: 3rem;
      padding-bottom: 3rem;
}

.mf-dmediation2-container{
      display: flex;
      justify-content: center;
      align-items: center;
      
}

.mf-dmediation2-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      text-align: center;  
}

.mf-dmediation-title-43m69f57 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}
.mf-dmediation-text-60m50f25 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

/*divorce 3 section ends*/

/*divorce 4 section start*/
.mf-dmediation3-section4{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-secondary);
      margin-top: 0rem;
      padding-bottom: 3rem;
}

.mf-dmediation3-container{
      display: flex;
      justify-content: center;
      align-items: center;  
}

.mf-dmediation2-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 1rem;
      margin-bottom: 0rem;  
}

.mf-dmediation-title-37m86f59 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-dmediation2-content-text{
      position: relative;  
}

.mf-dmediation2-content-text .mf-dmediation-list2{
      margin: 1rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-dmediation-list2 .dmediation2-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-500);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-dmediation-list2 .dmediation2-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-dmediation-list2 .dmediation2-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

@media (max-width: 650px){
      .mf-dmediation2-content-text .mf-dmediation-list2{
            margin: 2px;
      }
}
/*divorce 4 section ends*/

/*divorce 5 section starts*/
.mf-dmediation4-section5{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 3rem;
      padding-bottom: 3rem;
}

.mf-dmediation4-container{
      display: flex;
      justify-content: center;
      align-items: center;
      
}

.mf-dmediation4-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      align-items: center;
      text-align: center;  
}

.mf-dmediation-title-65m87f35 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
}

.mf-dmediation-text-43m98f10 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

.mf-dmediation-text-43m98f10 .mf-text-description b{
      font-family: var(--ff-lato);
}
/*divorce 5 section ends*/

/*divorce 6 section strats*/
.mf-dmediation5-section6{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-dmediation5-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-dmediation5-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 1rem;
      margin-bottom: 0rem; 
}

.mf-dmediation-title-29m75f46 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-secondary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-dmediation5-content-text{
      position: relative;
      width: 100%;
}

.mf-dmediation5-content-text .mf-dmediation5-list3{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-dmediation5-list3 .dmediation5-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-dmediation5-list3 .dmediation5-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-dmediation5-list3 .dmediation5-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-light);
}

.dmediation5-has-before b{
      font-family: var(--ff-lato);
}

.dmediation5-has-before i{
      font-size: var(--fs-7);
}

@media (max-width: 650px){
      .mf-dmediation5-content-text .mf-dmediation5-list3{
            margin: 2px;
      }
}
/*divorce 6 section ends*/

/*divorce 7 section starts

/*divorce 7 section ends*/

/*Contact section starts*/

.mf-mediation-contact-section-27m10f36{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5rem;
      padding-bottom: 5rem;
      background: var(--bs-secondary);
      overflow: hidden;   
}

.mf-mediation-contact-container{
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;   
     
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      /*background: pink;*/
}

.mf-mediation-contact-content{
      padding-bottom: 2rem;
}

.mediation-info-title-26e5f30c .mf-heading-title{
      font-size: var(--fs-2);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-white);
      line-height: 1.8;
}

.mediation-info-text-22a7b451 .mf-text-description{
      font-size: var(--fs-4);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.8;
}

.mediation-info-text-32m10f63 .mf-text-description{
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-light);
      line-height: 1.8;
}

@media (max-width: 800px){
      .mf-mediation-contact-section-27m10f36{
            padding: 1rem 1rem !important;
      }

      .mediation-info-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-4);
      }
      .mediation-info-text-22a7b451 .mf-text-description{
            font-size: var(--fs-6);
      }
      .mediation-info-text-32m10f63 .mf-text-description{
            font-size: var(--fs-8);
      }
}

@media (max-width: 546px){
      .mf-mediation-contact-section-27m10f36{
            padding: 1rem 1rem !important;
      }

      .mediation-info-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-5);
      }
      .mediation-info-text-22a7b451 .mf-text-description{
            font-size: var(--fs-7);
      }
      .mediation-info-text-32m10f63 .mf-text-description{
            font-size: var(--fs-9);
      }
}

.mf-mediation-contact-btn{
      margin-top: 2rem;
      padding: 2rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-800);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      align-items: center;
      color: var(--bs-rosa);
}

@media (max-width: 546px){
      .mf-mediation-contact-btn{
            font-size: var(--fs-9);
            font-weight: var(--fw-600);
            letter-spacing: 0px;  
      }
}
/*contact section ends*/
