/*hero section starts*/
.section-img-bg-51m03f64{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_csaladi_mediacio.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}
@media (max-width: 1028px){
      .section-img-bg-51m03f64{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-51m03f64{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-51m03f64{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/   
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-heading-title span{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
}

.hero-title-04m23f95 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-04m23f95 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*Family mediation first section starts*/
.mf-fmediation-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-fmediation-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-fmediation-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-fmediation-section .mf-fmediation-info{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-fmediation-text-07m35f81 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

.mf-fmediation-section .mf-fmediation-img{
      flex: 0 0 40%;
      max-width: 100%;
      position: relative;
      text-align: center;
      /*background: chocolate;*/
}

.mf-fmediation-section .mf-fmediation-img img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 30em;
}

@media (max-width: 650px){
      .mf-fmediation-row{
            display: grid;
            width: 400px;
      }
      .mf-fmediation-section .mf-fmediation-info{
            max-width: 100%;
      }
}
/*Family mediation first section ends*/

/*Family mediation second section starts*/
.mf-fmediation1-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-fmediation1-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-fmediation-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-fmediation-title-06m35f27 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-fmediation-content-text{
      position: relative;
}

.mf-fmediation-content-text .mf-fmediation-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-fmediation-list .fmediation-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-fmediation-list .fmediation-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-fmediation-list .fmediation-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}
/*Family mediation second section ends*/

/*Family mediation 3 section starts

/*Family mediation 3 section ends*/

/*Mediation contact section starts*/

.mf-mediation-contact-section-27m10f36{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5rem;
      padding-bottom: 5rem;
      background: var(--bs-secondary);
      overflow: hidden;   
}

.mf-mediation-contact-container{
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;   
     
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      /*background: pink;*/
}

.mf-mediation-contact-content{
      padding-bottom: 2rem;
}

.mediation-info-title-26e5f30c .mf-heading-title{
      font-size: var(--fs-2);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-white);
      line-height: 1.8;
}

.mediation-info-text-22a7b451 .mf-text-description{
      font-size: var(--fs-4);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.8;
}

.mediation-info-text-32m10f63 .mf-text-description{
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-light);
      line-height: 1.8;
}

@media (max-width: 800px){
      .mf-mediation-contact-section-27m10f36{
            padding: 1rem 1rem !important;
      }

      .mediation-info-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-4);
      }
      .mediation-info-text-22a7b451 .mf-text-description{
            font-size: var(--fs-6);
      }
      .mediation-info-text-32m10f63 .mf-text-description{
            font-size: var(--fs-8);
      }
}

@media (max-width: 546px){
      .mf-mediation-contact-section-27m10f36{
            padding: 1rem 1rem !important;
      }

      .mediation-info-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-5);
      }
      .mediation-info-text-22a7b451 .mf-text-description{
            font-size: var(--fs-7);
      }
      .mediation-info-text-32m10f63 .mf-text-description{
            font-size: var(--fs-9);
      }
}

.mf-mediation-contact-btn{
      margin-top: 2rem;
      padding: 2rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-800);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      align-items: center;
      color: var(--bs-rosa);
}

@media (max-width: 546px){
      .mf-mediation-contact-btn{
            font-size: var(--fs-9);
            font-weight: var(--fw-600);
            letter-spacing: 0px;  
      }
}
/*Mediation contact section ends*/


