/*hero section start*/
.section-img-bg-47m58f32{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_mozaikcsalad_konzultacio.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .section-img-bg-47m58f32{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-47m58f32{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-47m58f32{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
}


.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/
      
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-heading-title span{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
}

.hero-title-24m87f64 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-24m87f64 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*mosaic-first section starts*/
.mf-mosaic-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 2rem;
      padding-bottom: 2rem;
      overflow: hidden;
}

.mf-mosaic-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: red;*/
}

.mf-mosaic-text-block{
      display: block;
      width: 100%;
      padding-left: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem;
      /*background: yellow;*/
}

.mf-mosaic-text-79m84f61 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
      margin-top: 1rem;
      padding-bottom: 1rem;
}
/*mosaic-first section ends*/

/*Mosaic second section starts*/
.mf-mosaic1-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-secondary);
}

.mf-mosaic1-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-mosaic-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-mosaic1-section2 .mf-mosaic-info{
      flex: 0 0 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-mosaic-text-12m32f63 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      line-height: 1.5;
      padding-top: 2rem;
      padding-bottom: 1rem;
}

.mf-mosaic-text-11m32f61 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      line-height: 1.5;
}  

.mf-mosaic1-section2 .mf-mosaic-img{
      flex: 0 0 40%;
      position: relative;
      text-align: center;
      /*background: chocolate;*/
}

.mf-mosaic1-section2 .mf-mosaic-img img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 20em;
}

@media (max-width: 768px){
      .mf-mosaic-row .mf-mosaic-info{
            flex: 0 0 100%;
      }

      .mf-mosaic-row .mf-mosaic-img{
            flex: 0 0 100%;

      }
}


/*Mosaic-second section ends*/

/*Mosaic-3 toggle section starts*/
.mf-mosaic2-section3{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-mosaic2-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-mosaic1-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-mosaic1-row .mf-mosaic-info1{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-mosaic-title-65m21f82 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-rosa);
      text-align: left;
      line-height: 1.5;
      margin: 2rem 0;
}

@media (max-width: 650px){
      .mf-mosaic1-row{
            display: grid;
            width: 380px;
      }
      .mf-mosaic2-section3 .mf-mosaic-info1{
            max-width: 100%;
      }
}

/*toggle start*/
.accordion{
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
}

.contentBx{
      position: relative;
      border-bottom: 1px solid var(--bs-secondary);
}

.accordion .contentBx .label4{
      position: relative;
      padding: 20px;
      background: transparent;
      color: var(--bs-primary);
      cursor: pointer;
      font-size: var(--fs-6);
      font-family: var(--ff-baloo);
      font-weight: var(--fw-600);
} 

.accordion .contentBx .label4:before{
      content: '+';
      position: relative;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: var(--bs-secondary);
}

.accordion .contentBx.active .label4{
      background: var(--bs-primary);
      color: var(--bs-white);
}

.accordion .contentBx.active .label4:before{
      content: '-';
      color: var(--bs-rosa);
}

.accordion .contentBx .content4{
      position: relative;
      background: var(--bs-light1);
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      overflow-y: auto;
}

.accordion .contentBx .content4 p{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      padding: 10px 20px;
      margin: 1rem auto;
}

.accordion .contentBx .content4 p a{
      color: var(--bs-primary);
      text-decoration: underline;
}
.accordion .contentBx.active .content4{
      height: auto;
}
/*Mosaic-3 toggle section starts*/
/*contact törölve*/
