
/*hero section starts*/
.section-img-bg-29m06f28{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_iskolai_mediacio.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}
@media (max-width: 1028px){
      .section-img-bg-29m06f28{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-29m06f28{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-29m06f28{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/   
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}


.hero-title-42m71f95 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-42m71f95 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*School mediation first section starts*/
.mf-smediation-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-smediation-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-smediation-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-smediation-title-14m06f17 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-smediation-content-text{
      position: relative;
}

.mf-smediation-content-text .mf-smediation-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-smediation-list .smediation-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-smediation-list .smediation-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-smediation-list .smediation-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

@media (max-width: 650px){
      .mf-smediation-content-text .mf-smediation-list{
            margin: 0.1rem;
      }
}
/*School mediation first section ends*/

/*School mediation second section starts*/
.mf-smediation1-section2{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      margin-top: 1rem;
      margin-bottom: 1rem;
}

.mf-smediation1-container{
      display: flex;
      justify-content: center;
      align-items: center;
}

.mf-smediation-heading-box{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      text-align: center;
      
}

.mf-smediation-title-45m30f08 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      margin-left: 2rem;
      text-align: center;
}
/*School mediation second section ends*/

/*School mediation 3 section starts*/
.mf-smediation2-section3{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 1rem;
      margin-bottom: 3rem;
}

.mf-smediation2-container{
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, auto));
      justify-content: center;
      gap: 3rem;
}

.smediation-card{
      width: 100%;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      box-shadow: 0 4px 14px hsl(355deg 25% 15% / 10%);
      padding: 15px;
      border-radius: 0.5rem;
      border: 0.5px solid var(--bs-card-bg);
}

.smediation-card-title{
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      text-align: center;
      margin: 1rem auto;
      padding-left: 1rem;
      overflow: hidden;
}

.smediation-card-description{
      font-size: var(--fs-9);
      font-weight: var(--fw-400);
      color: var(--bs-light);
      font-family: var(--ff-lato);
      /*line-height: 1.5;*/
      line-height: 2rem;
      margin: 5px 0 10px;
      padding: 1rem 1rem;
      text-align: left;
      overflow: hidden;
}
/*School mediation 3 section ends*/












/*School mediation 4 section starts*/
.mf-mediation-info-section{
      display: flex;
      justify-content: center;
      align-items: center;
      background: var(--bs-body-bg);
      overflow: hidden;
      margin-top: 5rem;
      margin-bottom: 5rem;
}

.mf-mediation-info-container{
      display: grid;
      grid-template-columns: 50% 50%;
      justify-content: center;
      gap: 3rem;
      background: var(--bs-rosa);
      border-radius: 5px;
      
}

/*Info content
.mf-mediation-info-box{
      position: relative;
      display: flex;
      padding: 20px;
      align-items: center;
      justify-content: center;
      
}

.mediation-info-title{
      color: var(--bs-white);
      font-size: var(--fs-4);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      justify-content: center;
      align-items: center;
      text-align: center;
      margin: 2rem auto;
}


.mf-mediation-info-btnBx{
      position: relative;
      display: flex;
      padding: 15px;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 2rem auto;
}

.mf-mediation-info-btn{
      font-size: var(--fs-5);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      padding: 2rem;
      border-radius: 25px;
}

@media (max-width: 500px){
      .mediation-info-title{
            font-size: var(--fs-8);
          
      } 
      .mf-mediation-info-btn{
            font-size: var(--fs-7);
            padding: 1rem;
            margin-right: 2rem;
      } 
}
School mediation 4 section ends*/













/*contact section starts*/
.mf-contact-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding-top: 3rem;
      padding-bottom: 3rem;
      overflow: hidden;
      background: url(../image/mf_contact_form_2_bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
}

.mf-contact-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: rgba(255,255,255,0.1);
      border-radius: 20px;
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
      backdrop-filter: blur(10px);
      /*background: palevioletred;*/
}

.mf-contact-title-21m23f47 .mf-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.5;
      letter-spacing: 0.7px;
      margin-top: 1rem; 
}

.padd-10{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-contact-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-left: -15px;
      padding-right: -15px;
      justify-content: center;
      /*background: navajowhite;*/

}

.mf-contact-section .mf-contact-row .mf-contact-content{
      flex: 0 0 30%;
      align-items: center;
      justify-content: center;
      text-align: center;
      /*background: orange;*/
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-title{
      text-transform: uppercase;
      color: var(--bs-primary);
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      margin: 2rem;
      justify-content: center;
      align-items: center;
      text-align: center;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3{
      display: flex;
      align-items: center;
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      color: var(--bs-primary);
      padding: 1rem 0;
}

.mf-contact-section .mf-contact-row .mf-contact-content .contact-info h3 i{
      padding-right: 1rem;
      color: var(--bs-rosa);
}

.mf-contact-section .mf-contact-row .mf-contact-form{
      flex: 0 0 60%;
      padding: 1rem;
      margin: 1rem;
      margin-bottom: 4rem;
      margin-left: 2rem;
      text-align: left;
      position: relative;
      /*background: green;*/
}

.mf-contact-section .mf-contact-row .mf-contact-form .box{
      position: relative;
      padding: 1rem 1rem;
      margin: 0.5rem 0;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      text-transform: none;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      width: 80%;
}

.mf-contact-section .mf-contact-row .mf-contact-form .massage{
      height: 10rem;
      resize: none;
      background-color: var(--bs-card-bg);
      border: 1px solid;
      border-radius: 5px;
      color: var(--bs-secondary);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 1rem 1.5rem;
      margin: 1rem 0;

}

.mf-contact-form .contact-btn{
      padding: 0.5rem 1rem;
      margin: 0.5rem 0;
      background: var(--bs-primary);
      color: var(--bs-white);
      cursor: pointer;
      font-size: var(--fs-7);
      letter-spacing: 1px;
      border-radius: 2rem;
      box-shadow: var(--box-shadow);
      transition: .5s ease;
}
.mf-contact-form .contact-btn i{
      padding: 0 .5rem;
      font-size: var(--fs-8);   
}

.mf-contact-form .contact-btn:hover{
      color: var(--bs-secondary);
}

/*contact section ends*/





/*back To Top button start*/
.back-to-top{
      position: fixed;
      bottom: 20px;
      right: 20px;
      height: 50px;
      width: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--bs-body-bg);
      border: 2px solid var(--bs-rosa);
      border-radius: 50%;
      text-decoration: none;
      transition: 0.2s ease-out;
      cursor: pointer;
}

.back-to-top span{
      color: var(--bs-rosa);
      font-size: 20px;
      justify-content: center;
      transition: 0.2s ease-out;
}

.back-to-top:hover{
      background-color: var(--bs-rosa);
}

.back-to-top span:hover{
      color: var(--bs-white);
      transform: translateY(-4px);
}


@media (max-width:992px) {
      .back-to-top{
            width: 40px;
            height: 40px;
      } 
      .back-to-top span{
            font-size: 15px;
      }
}

@media (max-width:768px) {
      .back-to-top{
            width: 32px;
            height: 32px;
      } 
      .back-to-top span{
            font-size: 12px;
      }
}
/*back To Top button ends*/




/*Footer starts*/

.copyright{
      background: var(--bs-body-bg);
      text-align: center;
      justify-content: center;
      padding: 2rem;
      background-size: cover;
      background-position: center;
 }

 .copyright .credit{
      text-align: center;
      font-size: var(--fs-9);
      color: var(--bs-primary);

 }

/*Footer ends*/