/*hero section start*/

.section-img-bg-62m14f61{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_hazassag_elott_tanacsadas.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      padding-top: 25rem;
      padding-bottom: 5rem;
      overflow: hidden;
}



@media (max-width: 450px){
      .section-img-bg-62m14f61{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-62m14f61{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      width: 90%;
      max-width: 1200px;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}


.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
      
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/
      
}

/*A hero text*/

 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-heading-title span{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
}

.hero-title-43m72f24 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}


.w-1{
      margin-top: 2rem !important;
      margin-bottom: 2rem !important;
}

@media (max-width: 450px){
      .hero-title-43m72f24 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*premarital first section starts*/


.mf-premarital-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.pre2{
      display: none;
}

.mf-premarital-container{
      display: flex;
      /*flex-direction: column;*/
      position: relative;
      align-items: center;
      justify-content: center;
      width: 100%;
      
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-premarital-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

/*img*/
.mf-premarital-section .mf-premarital-img{
      flex: 0 0 40%;
      max-width: 40%;
      position: relative;
      text-align: center;
      /*flex: 0 0 50%;
      max-width: 50%;
      position: relative;
      text-align: center;*/
      /*background: chocolate;*/
}

.mf-premarital-section .mf-premarital-img img{
      margin: auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 35em;
}

.mf-premarital-section .mf-premarital-info{
      flex: 0 0 60%;
      max-width: 100%;
      /*text-align: left;
      position: relative;
      background: orange;*/
}

.mf-premarital-text-04m06f54{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

.mf-premarital-text-04m09f70{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}

.mf-premarital-text-04m10f43{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      margin: 1rem auto;
      padding-bottom: 1rem;
}
.mf-premarital-text-04m10f43 b{
      font-family: var(--ff-lato);
}

@media (max-width: 956px){
      .mf-premarital-section .mf-premarital-img img{
            height: 25em;
      }

}@media (max-width: 450px){
      .mf-premarital-section .mf-premarital-img img{
            height: 35em;
      }
}


/*premarital first section ends*/

/*premarital second section start*/
.mf-premarital-section-img-bg-76m23f84{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_premarital_img_ring.png);
      background-repeat: no-repeat;
      background-position: top center;
      background-size: cover;
      padding-top: 10rem;
      padding-bottom: 10rem;
      overflow: hidden;
}

.mf-premarital-container-2{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
}

.mf-premarital-text-06m04f91{
      font-size: var(--fs-4);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-white);
      line-height: 1.5;
      margin: 2rem auto;
      padding-bottom: 1rem;
}
/*premarital second section ends*/

/*premarital 3 section starts*/
.mf-premarital-section-3{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: var(--bs-body-bg);
      background-size: cover;
      padding-top: 5rem;
      padding-bottom: 2rem;
      overflow: hidden;
}

.mf-premarital-container-3{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin-bottom: 3rem; 
     
}

.mf-premarital-text-43m56f84{
      font-size: var(--fs-6);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      line-height: 2;
      margin: 1rem auto;
      padding-bottom: 1rem;
}

.mf-premarital-container-4{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items:flex-start;
      justify-content:flex-start;
      text-align: left;
      margin-bottom: 0;
      
}
.mf-premarital-text-54m87f61{
      font-size: var(--fs-3);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-rosa);
      line-height: 1.5;
      margin: 0rem auto;
      
      
}
/*premarital 3 section ends*/





/*premarital 4 section starts*/
.mf-premarital-section-4{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 3rem;
      overflow: hidden;  
}

.mf-premarital-container-5{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center; 
      
}

.mf-premarital-text-block{
      display: block;
      width: 100%;
      padding: 0.5rem;
      text-align: left;
      overflow: hidden; 
      margin-bottom: 3rem;
      
}

.mf-premarital-content-text{
      position: relative;
      width: 80%;
}

.mf-premarital-content-text .mf-premarital-list{
      
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-premarital-list .premarital-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-premarital-list .premarital-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-premarital-list .premarital-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}
/*premarital 4 section ends*/

/*premarital 5 section starts*/
.mf-premarital-section-5{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-secondary);
}

.mf-premarital-container-6{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-15{
      padding-left: 15px;
      padding-right: 15px;
}

/*img*/
.mf-premarital-section-5 .mf-premarital-img-1{
      flex: 0 0 50%;
      max-width: 100%;
      position: relative;
      text-align: center;
      /*background: chocolate;*/
}

.mf-premarital-section-5 .mf-premarital-img-1 img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 35em;
}



.mf-premarital-section-5 .mf-premarital-info-1{
      flex: 0 0 50%;
      max-width: 100%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-premarital-title-52m67f34{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}



.mf-premarital-content-text-1 .mf-premarital-list-1{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-premarital-list-1 .premarital-1-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-premarital-list-1 .premarital-1-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-premarital-list-1 .premarital-1-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

/*premarital 5 section ends*/
@media (max-width: 650px){

      .pre1{
            display: none;
      }

      .pre2{
            display:grid;
      }
      
      .mf-premarital-section .mf-premarital-info{
            flex: 0 0 100%;
      }
      .mf-premarital-content-text-1 .mf-premarital-list-1{
            margin: 0;
      }
      .mf-premarital-content-text{
            width: 100%;
      }
}
/*premarital 6 toggle section starts*/
.mf-premarital-section-6{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-premarital-container-7{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
}

.mf-premarital-row-1{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-premarital-row-1 .mf-premarital-info-3{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
}

.mf-premarital-title-47m74f74{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-rosa);
      text-align: left;
      line-height: 1.5;
      margin: 2rem 0;
}

@media (max-width: 650px){
      .mf-premarital-row-1 .mf-premarital-info-3{
            min-width: 100%;
      }
}

/*togle start*/
.accordion{
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
}

.contentBx{
      position: relative;
      border-bottom: 1px solid var(--bs-secondary);
}


.accordion .contentBx .label6{
      position: relative;
      padding: 20px;
      background: transparent;
      color: var(--bs-primary);
      cursor: pointer;
      font-size: var(--fs-6);
      font-family: var(--ff-baloo);
      font-weight: var(--fw-600);
} 

.accordion .contentBx .label6:before{
      content: '+';
      position: relative;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: var(--bs-secondary);
}

.accordion .contentBx.active .label6{
      background: var(--bs-primary);
      color: var(--bs-white);
}

.accordion .contentBx.active .label6:before{
      content: '-';
      color: var(--bs-rosa);
}

.accordion .contentBx .content6{
      position: relative;
      background: var(--bs-light1);
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      overflow-y: auto;
}

.accordion .contentBx .content6 p{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      padding: 10px 20px;
      margin: 1rem auto;
}


.accordion .contentBx .content6 p a{
      color: var(--bs-primary);
      text-decoration: underline;
}
.accordion .contentBx.active .content6{
      height: auto;
}

/*premarital 6 toggle section starts*/

/*Contact section starts*/

/*Contact section ends*/
