/*Hero section start*/
.section-img-bg-76m92f15{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_szulo_coaching.png);
      background-repeat: no-repeat;
      background-position: top;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .section-img-bg-76m92f15{
      padding-top: 25rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 450px){
      .section-img-bg-76m92f15{
      padding-top: 20rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-76m92f15{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}



.mf-home-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: orange;*/
     
}

.mf-home-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
      
}

@media (min-width: 768px){
      .mf-home-column-wrap .mf-col-100{
            width: 100%;
      }
      
}


.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: #000;*/
      
}

/*Hero text*/

.mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.hero-title-39m24f58 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 450px){
      .hero-title-39m24f58 .mf-heading-title{
      font-size: var(--fs-2);
      }
}
/*hero section ends*/

/*Paerent first section starts*/
.mf-parent-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-parent-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-parent-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-parent-section .mf-parent-info{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-parent-title-18m32f67 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-parent-text-76m38f23 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      line-height: 1.5;
      padding-bottom: 1rem;
}



.mf-parent-section .mf-parent-img{
      flex: 0 0 40%;
      max-width: 100%;
      position: relative;
      text-align: center;
      /*background: chocolate;*/
}

.mf-parent-section .mf-parent-img img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 35em;
}

@media (max-width: 650px){
      .mf-parent-row{
            display: grid;
            width: 400px;
      }
      .mf-parent-section .mf-parent-info{
            max-width: 100%;
      }
}
/*Parent first section ends*/








/*Parent second section starts*/
.mf-parent1-section1{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-secondary);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-parent1-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-parent-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      margin-top: 2rem;
      margin-bottom: 0rem; 
}

.mf-parent-title-25m16f32 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-parent-content-text{
      position: relative;
}

.mf-parent-content-text .mf-parent-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-parent-list .parent-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-parent-list .parent-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-parent-list .parent-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

.mf-parent-text-41m62f43 .mf-text-description{
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      line-height: 1.5;
      padding-top: 2rem;
      padding-bottom: 1rem;
}
/*Parent second section ends*/

/*Parent-3 section starts*/
.mf-parent2-section3{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-parent2-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;  
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-parent1-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-parent1-row .mf-parent-info1{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-parent-title-22m09f01 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-rosa);
      text-align: left;
      line-height: 1.5;
      margin: 2rem 0;
}

@media (max-width: 650px){
      .mf-parent1-row{
            display: grid;
            width: 400px;
      }
      .mf-parent2-section3 .mf-parent-info1{
            max-width: 100%;
      }
}

/*toggle start*/
.accordion{
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
}

.contentBx{
      position: relative;
      border-bottom: 1px solid var(--bs-secondary);
}

.accordion .contentBx .label5{
      position: relative;
      padding: 20px;
      background: transparent;
      color: var(--bs-primary);
      cursor: pointer;
      font-size: var(--fs-6);
      font-family: var(--ff-baloo);
      font-weight: var(--fw-600);
} 

.accordion .contentBx .label5:before{
      content: '+';
      position: relative;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: var(--bs-secondary);
}

.accordion .contentBx.active .label5{
      background: var(--bs-primary);
      color: var(--bs-white);
}

.accordion .contentBx.active .label5:before{
      content: '-';
      color: var(--bs-rosa);
}

.accordion .contentBx .content5{
      position: relative;
      background: var(--bs-light1);
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      overflow-y: auto;
}

.accordion .contentBx .content5 p{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      padding: 10px 20px;
      margin: 1rem auto;
}

.accordion .contentBx .content5 p a{
      color: var(--bs-primary);
      text-decoration: underline;
}
.accordion .contentBx.active .content5{
      height: auto;
}
/*Parent-3 section ends*/

/*contact törölve*/
