/*hero section starts*/
.faq-section-img-bg-3m4f7587{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_faq.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 15rem;
      padding-bottom: 10rem;
      overflow: hidden;
}

@media (max-width: 1028px){
      .faq-section-img-bg-3m4f7587{
            padding-top: 25rem;
            padding-bottom: 5rem;
            background-position: center right;
      }
}

@media (max-width: 450px){
      .faq-section-img-bg-3m4f7587{
            padding-top: 10rem;
            padding-bottom: 1rem;
      }
}

@media (max-width: 310px){
      .faq-section-img-bg-3m4f7587{
            padding-top: 8rem;
            padding-bottom: 1rem;
      }
}

.mf-faq-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: orange;*/
     
}

.mf-faq-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      
      /*background: #333;*/
}

@media (min-width: 768px){
      .mf-faq-column-wrap .mf-col-100{
            width: 100%;
      }
      
}

.mf-faq-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: #000;*/  
}

/*A herohoz a szöveg kezdete*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.faq-title-7e1f8217 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-2);
      font-weight: var(--fw-800);
}

 .mf-text-description{
      color: var(--bs-light1);
      font-size: var(--fs-9);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);   
}

.faq-text-71642346 .mf-text-description{
      color: var(--bs-white);
      font-size: var(--fs-5);
      font-family: var(--ff-lato);
      font-weight: var(--fw-500);
      padding-top: 5rem;
}

@media (max-width: 470px){
      .faq-title-7e1f8217 .mf-heading-title{
      font-size: var(--fs-2);
      padding-top: 5rem;
     
      }

      .faq-text-71642346 .mf-text-description{
            font-size: var(--fs-7); 
      } 
}

/*Faq first section start*/
.mf-faqs-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 5rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

.mf-faqs-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
}

.mf-faqs-box{
      width: 100%;
      overflow: hidden;
      justify-content: center;  
}

.faqs-text-69b32e91 .mf-text-description{
      font-size: var(--fs-7);
      font-family: var(--ff-lato);
      font-weight: var(--fw-500);
      color: var(--bs-light);
      line-height: 1.5;
      text-align: center;
}

.mf-faqs-box .mf-text-description a{
      color: var(--bs-primary);
      
}
/*Faq first section ends*/

/*FAQ togle section sarts*/

.faq-toggle-section-62m5f296{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: var(--bs-body-bg);
      background-size: cover;
      padding-top: 2rem;
      padding-bottom: 5rem;
      overflow: hidden;
      /*background: navajowhite;*/
}

@media (max-width: 450px){
      .faq-toggle-section-62m5f296{
            padding-top: 5rem;
            padding-bottom: 5rem;
      }
}

@media (max-width: 310px){
      .faq-toggle-section-62m5f296{
            padding-top: 7rem;
            padding-bottom: 2rem;
      }
}

.mf-faq-toggle-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
}

.accordion{
      width: 85%;
      overflow: hidden;
}

@media (max-width: 768px){
      .accordion{
            width: 100%;
      }
}

.accordion .contentBx{
      position: relative;
      border: 1px solid var(--bs-card-bg);
}

.accordion .contentBx .label{
      position: relative;
      padding: 20px;
      background: transparent;
      color: var(--bs-primary);
      cursor: pointer;
      font-size: var(--fs-6);
      font-family: var(--ff-baloo);
      font-weight: var(--fw-600);
}

.accordion .contentBx .label:before{
      content: '+';
      position: relative;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: var(--bs-secondary);
}

.accordion .contentBx.active .label{
      background: var(--bs-primary);
      color: var(--bs-white); 
}

.accordion .contentBx.active .label:before{
      content: '-';
      color: var(--bs-white);
}

.accordion .contentBx .content{
      position: relative;
      background: var(--bs-light1);
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      overflow-y: auto;
}

.accordion .contentBx .content p{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      padding: 10px 20px;
      margin: 1rem auto;
}

.accordion .contentBx.active .content{
      height: auto;     
}

.content .accordion-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.accordion-list .accordion-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.accordion-list .accordion-has-before:not(:last-child) {margin-block-end: 15px;}

.accordion-list .accordion-has-before::before{
    content: '';
    position: absolute;
    top: 10px;
    left:10px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--bs-primary);
}

@media (max-width: 960px){
      .accordion .contentBx .content p{
            font-size: var(--fs-8);
            font-weight: var(--fw-500);
      }
}

@media (max-width: 564px){
      .accordion .contentBx .label{
            font-size: var(--fs-8);
            font-weight: var(--fw-600);
      }
      .accordion .contentBx .content p{
            font-size: var(--fs-8);
            font-weight: var(--fw-500);
      }      
}

@media (max-width: 334px){
      .accordion .contentBx .content p{
            font-size: var(--fs-10);
            font-weight: var(--fw-600);
      }      
}

/*FAQ togle section ends*/

/*Contact section starts*/

.faq-contact-section{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 3rem;
      background: var(--bs-secondary);
      overflow: hidden;
      
}

.mf-faq-contact-container{
      display: flex;
      flex-flow: row;
      position: relative;
      width: 90%;
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding: 2rem;
      /*background: pink;*/

}

.mf-faq-contact-content-container{
      align-self: center;
}

.mf-faq-contact-content-container{
      width: 100%;
}

.mf-faq-contact-content{
      text-align: center;
}

.faq-contact-title-26e5f30c .mf-heading-title{
      font-size: var(--fs-1);
      font-weight: var(--fw-700);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.8;
}

.faq-contact-text-22a7b451 .mf-text-description{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      line-height: 1.8;
}

@media (max-width: 546px){
      .faq-contact-section{
            padding: 2rem 2rem !important;
      }

      .faq-contact-title-26e5f30c .mf-heading-title{
            font-size: var(--fs-2);
      }
      .faq-contact-text-22a7b451 .mf-text-description{
            font-size: var(--fs-7);
      }
}

/*contact section ends*/
