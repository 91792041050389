/* Hero starts*/
.section-img-bg-2m2f2458{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf-about-bg.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 450px){
      .section-img-bg-2m2f2458{
      padding-top: 20rem;
      padding-bottom: 1rem;
}
}

@media (max-width: 310px){
      .section-img-bg-2m2f2458{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-hero-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/    
}

.mf-col-100{
      /*background: blue;*/
      margin: 2rem;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100 {
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/  
}

/*A hero text*/
 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
      margin-top: 100px;
}

.about-title-50b2c440 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}

@media (max-width: 1000px) {
      .mf-heading-title{
            margin-top: 100px;
      }
}

@media (max-width: 450px){
      .title-50b2c440 .mf-heading-title{
      font-size: var(--fs-6);
      }

      .text-49a4f512 .mf-text-description{
            font-size: var(--fs-7); 
      } 

}

/*about-quote-container*/
.mf-container-1{
      display: flex;
      position: relative;
      margin: 2rem auto;
      justify-content: center;
      align-items: center;
      /*background: gray;*/    
}

.mf-about-quote-container{
      width: 100%;
      height: 100%;
      justify-content: end;
      /*background: #fff;*/
}

.about-quote-box{
      position: relative;
      padding: 1rem auto;
      /*background: #f84b70;*/    
}

.mf-about-quote-container h6{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      line-height: 1.5;
      text-align: end;
      z-index: 2;    
}

.mf-about-quote-container p{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-9);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: end;
      z-index: 2;
}

@media (max-width: 450px){
      .mf-container-1{
            margin: 1rem auto;
      }
      .about-quote-box{
            padding: 0;
      }
      .mf-about-quote-container h6{
            font-size: var(--fs-9);
      }
      .mf-about-quote-container p{
            font-size: var(--fs-9);
      }
}

@media (max-width: 310px){
      .mf-about-quote-container h6{
            display: none;
      }
      .mf-about-quote-container p{
            display: none;
      }
}
/*about-quote container end*/
/* Hero ends*/

/*About-section-profile starts*/
.mf-section-about{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: var(--bs-body-bg);
      background-size: cover;
      padding-top: 10rem;
      padding-bottom: 10rem;
      overflow: hidden;
      /*background: white;*/
}

.mf-about-container{
      display: flex;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      padding: 2rem;
      background: var(--bs-secondary);
}

.mf-about-container .mf-about-profile-continer{
      position: relative;
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background: var(--bs-white);
}


.mf-about-container .mf-about-profile-continer .col-left{
      position: relative;
      margin: 2rem;
      width: 80%;
      /*background: palegoldenrod;*/
}

.mf-about-container .mf-about-profile-continer .col-right{
      position: relative;
      margin: 2rem;
     
      /*background: #444242;*/
}

.mf-about-profile{
      position: relative;
      width: 100%;
      min-height: 300px;     
}

.mf-about-container .mf-about-profile-continer .col-right .mf-about-profile img{
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
}

.mf-about-title-3b1f2157 .mf-heading-title{
      color: var(--bs-primary);
      font-family: var(--ff-baloo);
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      line-height: 2;
      margin-top: 1rem;
      margin-bottom: 3rem;
}

.mf-about-text-8e4f6371 .mf-text-description{
      line-height: 1.5;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      padding: 0 1rem;
      margin: 3rem;
}

@media (max-width: 1000px) {
      .mf-about-container .mf-about-profile-continer{
            flex-direction: column;
      }

      .mf-about-text-8e4f6371{
           
            font-size: var(--fs-9);
            font-weight: var(--fw-400);
            font-family: var(--ff-lato);
            color: var(--bs-light);
            text-align: left;
            justify-content: left;
            padding: 0;
            margin: 0;
      }

      .mf-about-container .mf-about-profile-continer .col-right{
            position: relative;
            margin: 0;
           
            
      }
      .mf-about-container .mf-about-profile-continer .col-left{
            
            /*background: palegoldenrod;*/
      }
      .mf-about-text-8e4f6371 .mf-text-description{
            line-height: 1.5;
            font-size: var(--fs-7);
            font-weight: var(--fw-400);
            font-family: var(--ff-lato);
            color: var(--bs-light);
            text-align: left;
            padding: 0;
            margin: 1px;
      }

      .mf-about-container .mf-about-profile-continer .col-right .mf-about-profile img{
            width: 90%;
            height: 100%;
            object-fit: cover;
            border-radius: 10px;
            padding-bottom: 20px;
      }
}
@media (max-width: 450px) {
      .mf-about-text-8e4f6371 .mf-text-description{
            font-size: var(--fs-8);
            margin: 0.2rem;
      }
}
/*about-section-profile ends*/

/*about-section-glass starts*/

.about-section-img-bg{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 10rem;
      padding-bottom: 10rem;
      background: var(--bs-primary);
      overflow: hidden;
}

.mf-about-blue-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      text-align: center;
      /*background: var(--bs-white);*/
}

.mf-about-card-glass{
      width: 100%;
      min-height: 1px;
      background-color: rgba(255,255,255,0.1);
      padding: 2rem;
      border-radius: 10%;
      backdrop-filter: blur(15px);
      border: 2px solid transparent;
      background-clip:padding-box;
      box-shadow: var(--box-shadow);
}

.mf-about-glass-text h2{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-white);
      line-height: 2;
      margin: 3rem 0;
}

.mf-about-glass-text h3{
      font-size: var(--fs-5);
      font-weight: var(--fw-700);
      color: var(--bs-white);
      font-family: var(--ff-lato);
      padding: 3rem 0;
}

.mf-about-glass-text p{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-7);
      font-weight: var(--fw-400);
      margin: 2rem 0;
      line-height: 2em;
}

@media (max-width: 768px){

      .mf-about-card-glass{
            width: 100%;
      }
      .mf-about-glass-text p{
            font-size: var(--fs-7);
      }
}

@media (max-width: 450px){
      .mf-about-glass-text h3{
            font-size: var(--fs-5);
      }
      .mf-about-glass-text h4{
            font-size: var(--fs-7);
      }
      .mf-about-glass-text p{
            font-size: var(--fs-8);
      }
}
/*about-section-glass ends*/

/*time-line section starts*/
.about-section-color-bg{
      display: flex;
      position: relative;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5rem;
      padding-bottom: 5rem;
      background: var(--bs-body-bg);
      overflow: hidden;
}

.mf-about-white-container{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 3rem auto;
      background: var(--bs-white);

}

.mf-about-education-container{
      width: 100%;
      height: 100%;
      justify-content: left;
}

.mf-about-education-text h3{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 2;
      margin: 3rem 0;
      padding-left: 5rem;
}

/*timeline*/
.mf-about-white-container-1{
      display: flex;
      position: relative;
      width: 90%;
      max-width: 1200px;
      align-items: center;
      justify-content: center;
      margin: 3rem auto;
      /*background: var(--bs-secondary);*/
      
}

.mf-about-timeline-container{
      width: 100%;
      position: relative;
}

.mf-timeline-card-container{
      width: 100%;
      padding: 0 2%;
      margin-top: -90px;
      /*background: palegreen;*/
}

.mf-timeline-card{
      display: block;
      width: 45%;
      min-height: 170px;
      min-width: 200px;
      background: #f1f1f1;
      color: var(--bs-light);
      border: 1px solid #fcfcfc;
      border-radius: 10px;
      position: relative;
}

.mf-timeline-card-container:nth-child(1) .mf-timeline-card{
      margin-top: 60px;
      background-color: #ffd4dd;
}

.mf-timeline-card-container:nth-child(2) .mf-timeline-card{
      background-color: #fbd0a7;
}

.mf-timeline-card-container:nth-child(3) .mf-timeline-card{
      background-color: #c7dfda;
}

.mf-timeline-card-container:nth-child(4) .mf-timeline-card{
      background-color: #aad0d9;
}

.mf-timeline-card-container:nth-child(5) .mf-timeline-card{
      background-color: palegoldenrod;
}

.mf-timeline-card-container:nth-child(6) .mf-timeline-card{
      background-color: #f2afaf;
}

.mf-timeline-card-container:nth-child(7) .mf-timeline-card{
      background-color: #96d1c5;
}

.mf-timeline-card-container:nth-child(8) .mf-timeline-card{
      background-color: lightblue;
}
.mf-timeline-card-container:nth-child(even) .mf-timeline-card{
      margin-left: auto;
}


.mf-timeline-card-container:nth-child(odd) .mf-timeline-card::after{
      content: '';
      position: absolute;
      height: 120%;
      right: -11%;
      top: 50%;
      border-right: 2px dashed #f84b70;
      transform: translateY(-30%);
}

.mf-timeline-card-container .mf-timeline-card::before{
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: -7.5%;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      border: 3px solid #f84b70;
}

.mf-timeline-card-container:nth-child(even) .mf-timeline-card::before{
      left: -8%;
}

.mf-timeline-card-title{
      width: 100%;
      height: 60px;
      font-weight: var(--fw-500);
      background: rgba(255, 255, 255, 0.2);
      padding: 20px;
      font-size: var(--fs-7);
      line-height: 1.8;

}

.mf-timeline-card-text{
      padding: 30px;
      font-size: var(--fs-8);
      line-height: 1.8;
      opacity: 0.8;
      font-weight: var(--fw-600);
}

@media(max-width:540px){

      .mf-timeline-card-container{
            margin-top: 20px;
            /*background: palegreen;*/
      }
      
      .mf-timeline-card{
            display: block;
            width: 85%;
            margin: auto;
            min-height: 170px;
            min-width: 200px;
            background: #f1f1f1;
            color: var(--bs-light);
            border: 1px solid #fcfcfc;
            border-radius: 10px;
            position: relative;
      }

      .mf-timeline-card-container:nth-child(odd) .mf-timeline-card::after{
            content: '';
            position: absolute;
            height: 120%;
            right: -11%;
            top: 50%;
            border-right: 2px dashed #f84b70;
            transform: translateY(-30%);
            display: none;
      }
      
      .mf-timeline-card-container .mf-timeline-card::before{
            content: '';
            position: absolute;
            width: 10px;
            height: 10px;
            right: -7.5%;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            border: 3px solid #f84b70;
            display: none;
      }

      .mf-about-white-container-1{
            margin: -50px auto;
            /*background: var(--bs-secondary);*/
            
      }
      
      
}
/*timeline section ends*/
