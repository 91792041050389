/*Hero starts*/
.section-img-bg-4m6f7843{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      background: url(../image/mf_egyeni-konzultacio.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      padding-top: 20rem;
      padding-bottom: 5rem;
      overflow: hidden;
}

@media (max-width: 450px){
      .section-img-bg-4m6f7843{
      padding-top: 15rem;
      padding-bottom: 5rem;
}
}

@media (max-width: 310px){
      .section-img-bg-4m6f7843{
            padding-top: 7rem;
            padding-bottom: 2rem;
}
}

.mf-adult-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: rebeccapurple;*/
     
}

.mf-column-wrap{
      position: relative;
      min-height: 1px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 1rem;
      /*background: #333;*/
      
}

.mf-col-100{
      /*background: blue;*/
      margin: 0rem !important;
}
@media (min-width: 768px){
      .mf-column-wrap .mf-col-100{
            width: 100%;
      }  
}

.mf-widget-container{
      position: relative;
      width: 100%;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      /*background: red;*/
      
}

/*A hero text*/

 .mf-heading-title{
      color: var(--bs-light);
      font-family: var(--ff-baloo);
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      padding: 0;
      margin: 0;
      line-height: 1.5;
}

.mf-heading-title span{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-4);
      font-weight: var(--fw-400);
}

.adult-title-32m3f273 .mf-heading-title{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-1);
      font-weight: var(--fw-800);
}


@media (max-width: 450px){
      .adult-title-32m3f273 .mf-heading-title{
      font-size: var(--fs-2);
      }
}


/*about-quote-container*/
.mf-adult1-container{
      display: flex;
      position: relative;
      margin: 5rem;
      margin-bottom: 0rem;
      justify-content: center;
      align-items: center;
      /*background: gray;*/    
}

.mf-adult-quote-container{
      width: 100%;
      height: 100%;
      justify-content: end;
      /*background: #fff;*/
}

.adult-quote-box{
      position: relative;
      padding: 1rem auto;
      /*background: #f84b70;*/    
}

.mf-adult-quote-container h6{
      color: var(--bs-white);
      font-family: var(--ff-baloo);
      font-size: var(--fs-7);
      font-weight: var(--fw-500);
      line-height: 1.5;
      text-align: end;
      z-index: 2;    
}

.mf-adult-quote-container p{
      color: var(--bs-white);
      font-family: var(--ff-lato);
      font-size: var(--fs-9);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: end;
      z-index: 2;
}

@media (max-width: 450px){
      .mf-adult1-container{
            margin: 1rem auto;
      }
      .adult-quote-box{
            padding: 0;
      }
      .mf-adult-quote-container h6{
            font-size: var(--fs-9);
      }
      .mf-adult-quote-container p{
            font-size: var(--fs-9);
      }
}

@media (max-width: 310px){
      .mf-adult-quote-container h6{
            display: none;
      }
      .mf-adult-quote-container p{
            display: none;
      }
}
/*about-quote container end*/
/*Hero ends*/

/*Adult first section start*/

.mf-adult-section{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 10rem;
      padding-bottom: 5rem;
      overflow: hidden;
      /*background: var(--bs-primary);*/
}

.mf-adult2-container{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: palevioletred;*/

}

.padd-25{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-adult-row{
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
      /*background: green;*/

}

.mf-adult-section .mf-adult-img{
      flex: 0 0 40%;
      max-width: 40%;
      text-align: center;
      position: relative;
      /*background: chocolate;*/
}

.mf-adult-section .mf-adult-img img{
      margin: auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 40em;
}


.mf-adult-section .mf-adult-info{
      flex: 0 0 60%;
      max-width: 60%;
}


.mf-adult-title-26m4f227 .mf-heading-title{
      font-size: var(--fs-3);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
      padding: 2rem 0;
}


.padd-25 .mf-adult-title-26m4f227:after{
      content: '';
      display: none;
      width: 100%;
      max-width: 150px;
      height: 2px;
      top: 50%;
      left: 50%;
      transform: translate(190%, 0%);
      background-color: var(--bs-primary);
      margin-top: 2rem;
      margin-bottom: 5rem;
     
}

.mf-adult-text-20m02f57 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: center;
      line-height: 1.5;
      margin: 1rem 0;
}

@media (max-width: 650px){
      .mf-adult-row{
            display: grid;
            width: 400px;
      }
      .mf-adult-section .mf-adult-info{
            max-width: 100%;
      }
}

/*button- start*/

.mf-adult-btn-box{
      text-align: center;
}
.mf-adult-btn{
      margin: 2rem;
      padding: 2rem;
      font-size: var(--fs-7);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      letter-spacing: 0.7px;
      line-height: 1rem;
      border-radius: 2rem;
      background: var(--bs-light1);
      box-shadow: var(--box-shadow);
      align-items: center;   
      color: var(--bs-rosa);
      opacity: 1;
}

.mf-adult-btn:hover{
      background: var(--bs-primary);
      color: var(--bs-white);
}
/*button end*/
/*Adult first section ends*/

/*Adult second section starts*/
.mf-adult1-section2{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 2rem;
      padding-bottom: 2rem;
      overflow: hidden;
      
}

.mf-adult3-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      /*background: red;*/
      
}

.mf-adult-text-block{
      display: block;
      width: 100%;
      padding-left: 1rem;
      text-align: left;
      overflow: hidden; 
      /*background: yellow;*/
}

.mf-adult-title-67m43f12 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-adult-text-14m5f346 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      color: var(--bs-light);
      text-align: left;
      line-height: 1.5;
}

.mf-adult-text-block .mf-text-description a{
      color:var(--bs-primary);
      text-decoration: underline;
}
/*Adult second section end*/

/*Adult-3 section start*/
.mf-adult2-section3{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      background: var(--bs-body-bg);
      padding-top: 0rem;
      padding-bottom: 2rem;
      overflow: hidden;  
}

.mf-adult4-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;    
}

.mf-adult-text-block{
      display: block;
      width: 100%;
      padding: 1rem;
      text-align: left;
      overflow: hidden; 
      
}

.mf-adult-title-67m43f12 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-adult-content-text{
      position: relative;
}

.mf-adult-content-text .mf-adult-list{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-adult-list .adult-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-400);
      font-family: var(--ff-lato);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-adult-list .adult-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-adult-list .adult-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

@media (max-width: 650px){
      .mf-adult-content-text .mf-adult-list{
            margin: 0.1rem;
      }
}
/*Adult-3 section ends*/

/*Adult-4 section starts*/
.mf-adult3-section4{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-secondary);
}

.mf-adult5-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-adult2-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-adult3-section4 .mf-adult-info1{
      flex: 0 0 60%;
      max-width: 60%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-adult-title-78m27f13 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-800);
      font-family: var(--ff-baloo);
      color: var(--bs-primary);
      text-align: left;
      line-height: 1.5;
      margin: 1rem 0;
}

.mf-adult-content-text1{
      position: relative;
}

.mf-adult-info1 .mf-adult-list1{
      margin: 3rem 2rem;
      display: grid;
      grid-template-columns: 1fr;
      list-style: none;
}

.mf-adult-list1 .adult1-has-before{
      position: relative;
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      padding-inline-start: 30px;
      line-height: 1.5;
}

.mf-adult-list1 .adult1-has-before:not(:last-child) {margin-block-end: 10px;}

.mf-adult-list1 .adult1-has-before::before{
      content: '';
      position: absolute;
      top: 10px;
      left:10px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--bs-primary);
}

.mf-adult-text-25m65f98 .mf-text-description{
      font-size: var(--fs-8);
      font-weight: var(--fw-600);
      font-family: var(--ff-lato);
      color: var(--bs-primary);
      line-height: 1.5;
}

.mf-adult3-section4 .mf-adult-img-1{
      flex: 0 0 40%;
      max-width: 40%;
      position: relative;
      text-align: center;
      /*background: chocolate;*/
}

.mf-adult3-section4 .mf-adult-img-1 img{
      margin: 2rem auto;
      border-radius: 5px;
      border: 5px solid var(--bs-primary);
      height: 50em;
}

@media (max-width: 980px){
      .mf-adult3-section4 .mf-adult-img-1 img{
            height: 40em;
      }
}

@media (max-width: 760px){
      .mf-adult3-section4 .mf-adult-img-1 img{
            height: 35em;
      }
}

@media (max-width: 650px){
      .mf-adult3-section4 .mf-adult-img-1 img{
            height: 50em;
      }
}
/*Adult-4 section ends*/

/*Adult-5 section starts*/
.mf-adult4-section5{
      display: flex;
      position: relative;
      align-items: center;
      justify-content: center;
      padding-top: 3rem;
      padding-bottom: 5rem;
      overflow: hidden;
      background: var(--bs-body-bg);
}

.mf-adult6-container{
      display: flex;
      flex-direction: column;
      position: relative;
      align-items: center;
      justify-content: center;
      
}

.padd-20{
      padding-left: 15px;
      padding-right: 15px;
}

.mf-adult3-row{
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: -15px;
      margin-right: -15px;
      position: relative;
}

.mf-adult3-row .mf-adult-info2{
      flex: 0 0 80%;
      max-width: 100%;
      text-align: left;
      position: relative;
      /*background: orange;*/
}

.mf-adult-title-86m23f65 .mf-heading-title{
      font-size: var(--fs-5);
      font-weight: var(--fw-600);
      font-family: var(--ff-baloo);
      color: var(--bs-rosa);
      text-align: left;
      line-height: 1.5;
      margin: 2rem 0;
}

@media (max-width: 650px){
      .mf-adult2-row{
            display: grid;
            width: 400px;
      }
      .mf-adult3-section4 .mf-adult-info1{
            max-width: 100%;
      }
      .mf-adult3-row{
            display:contents;
            flex-wrap:nowrap;
            width: 400px;
            margin-left: 5px;
            margin-right: 5px;
            
      }
}

/*togle start*/
.accordion{
      display: flex;
      flex-direction: column;
      position: relative;
      margin-top: 1rem;
      margin-bottom: 1rem;
      
      
}

.contentBx{
      position: relative;
      border-bottom: 1px solid var(--bs-secondary);
      
      
}


.accordion .contentBx .label1{
      position: relative;
      padding: 20px;
      background: transparent;
      color: var(--bs-primary);
      cursor: pointer;
      font-size: var(--fs-6);
      font-family: var(--ff-baloo);
      font-weight: var(--fw-600);
     
} 

.accordion .contentBx .label1:before{
      content: '+';
      position: relative;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      font-size: 1.5em;
      color: var(--bs-secondary);
}

.accordion .contentBx.active .label1{
      background: var(--bs-primary);
      color: var(--bs-white);
}

.accordion .contentBx.active .label1:before{
      content: '-';
      color: var(--bs-rosa);
}

.accordion .contentBx .content1{
      position: relative;
      background: var(--bs-light1);
      height: 0;
      overflow: hidden;
      transition: 0.5s;
      overflow-y: auto;
}

.accordion .contentBx .content1 p{
      font-size: var(--fs-8);
      font-family: var(--ff-lato);
      font-weight: var(--fw-400);
      line-height: 1.5;
      text-align: left;
      padding: 10px 20px;
      margin: 1rem auto;
}


.accordion .contentBx .content1 p a{
      color: var(--bs-primary);
      text-decoration: underline;
}
.accordion .contentBx.active .content1{
      height: auto;
}



/*Adult-5 section ends*/
/*contact törölve*/
