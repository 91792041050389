@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');

.modalClass {  
    text-align: center;  
    margin-top: 200px;
    
  } 

.modal-content {
    max-width:800px;
}

.modal-body {
    max-width:800px;
}

.appli-box{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.appli-container{
    position: relative;
    margin: 0 28px;
    border-radius: 10px;
    overflow: hidden;
    background: rgba(255,255,255,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(15px);
    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
    border: 1px solid rgba(255,255,255,0.5);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
}

.form{
    max-width: 600px;
    position: relative;
    width: 100%;
    height: 100%;
    padding: 40px;
    color: #39232f;
    font-size: 1.5rem;
}

.modal-header .btn-close {
    font-size: 2rem;
}

.form h2{
    position: relative;
    color: #f84b70;
    font-size: 3rem;
    font-weight: 600;
    letter-spacing: 1px;
    margin-bottom: 40px;
}

.form h2::before{
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 150px;
    height: 2px;
    background: #f84b70;
}

.appli-inputBox{
    width: 100%;
    margin-top: 15px;
}

.appli-inputBox label{
    color: #136a80;
    font-size: 1.8rem;
    padding-top: 15px;
    margin-bottom: 5px;
}

.appli-inputBox input{
    width: 100%;
    background: rgba(255,255,255,0.2);
    border: none;
    padding: 10px 20px;
    border-radius: 35px;
    border: 1px solid rgba(255,255,255,0.5);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    font: 1.5rem;
    letter-spacing: 1px;
    color: #136a80;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}




.appli-inputBox textarea{
    width: 100%;
    background: rgba(255,255,255,0.2);
    border: none;
    padding: 10px 20px;
    border-radius: 35px;
    border: 1px solid rgba(255,255,255,0.5);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-bottom: 1px solid rgba(255,255,255,0.2);
    font: 1.5rem;
    letter-spacing: 1px;
    color: #136a80;
    box-shadow: 0 5px 15px rgba(0,0,0,0.2);
}

.alert p{
    font-size: 1rem;
    font-style: italic;
    line-height: 1.5;
}

.alert a{
    font-weight: 700;
    color: #136a80;
    text-decoration: none;
}

.alert a:hover{
    font-weight: 700;
    text-decoration: underline;
    color:#97bcc7;
}

.appli-button-container{
    margin: 0;
}

.appli-button-container .appli-button{
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 1px;
    color: #fff;
    background: #81b2bb;
    max-width: 300px;
    cursor: pointer;
    border-radius: 35px;
    padding: 10px 20px;

}

.appli-button-container .appli-button:hover{
    color: #fff;
    background-color: #f84b70;
}


@media(max-width: 800px)
{
    .modal-content {
        width:700px;
    }
    
    .modal-body {
        width:700px;
    }
   
    .form{
        font-size: 2.5rem;
    }

    .appli-button-container button{
        font-size: 3rem;
    }

    .form h2{
        font-size: 3rem;
    }

    .modal-header .btn-close {
        font-size: 5rem;
    }
    
}

@media(max-width: 800px)
{
    .modal-content {
        width:auto;
    }
    
    .modal-body {
        width:auto;
    }
}


